import { useTranslation as useNativeTranslation } from 'react-i18next'
import type { QueryKey } from 'react-query'

import type { DefaultOptionType } from 'antd/es/select'
import type { SortOrder } from 'antd/lib/table/interface'
import { BASE_API_URL } from 'constant'
import { get } from 'lodash'

import { queryClient } from 'components/Provider'
import { DateConfig } from 'components/Templates/Input/services/formatter'
import { FORMAT_BACKEND_DATE, FORMAT_DATE } from 'utils/constants'

export * from './objectUtils'
export {
  currentDay as observableCurrentDay,
  currentTime as observableCurrentTime,
} from '@legendapp/state/helpers/time'
export { default as dotObject } from 'dot-object'

export function useSuspenseTranslation(
  ...props: Parameters<typeof useNativeTranslation>
) {
  return useNativeTranslation(props[0], {
    useSuspense: true,
    ...(props[1] || {}),
  })
}

export const showImageBase64 = (
  imageSource?: string | null,
  { fallbackUrl = '/images/no-image.png' }: { fallbackUrl?: string } = {},
) => {
  if (imageSource) {
    return `data:image/png;base64,${imageSource}`
  }

  return fallbackUrl
}

interface ConvertDataToAntSelectOptionsReturnType<T = any>
  extends DefaultOptionType {
  label: string
  value: any
  disabled?: boolean
  original?: T
  key?: string
}

export const convertDataToAntSelectOptions = <T = any>(
  data: T[],
  labelKey: keyof T | string = 'name',
  valueKey: keyof T | string = 'id',
): ConvertDataToAntSelectOptionsReturnType<T>[] =>
  data.map((k, i) => ({
    label: get(k, labelKey),
    value: get(k, valueKey) as any,
    disabled: (k as any)?.disabled,
    original: k,
    key: [get(k, valueKey), i].join('__'),
  }))

export const filterSelectOptionLabel = (input: string, option: any) =>
  `${option?.label || option?.value}`
    .toLowerCase()
    .includes(input?.toLowerCase())

export const readInputFile = (file: File) => {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(this.result)
    }
    /* c8 ignore start */
    // reader.onerror = function (error) {
    // 	console.log('Error: ', error);
    // 	resolve(null);
    // };
    /* c8 ignore stop */
    reader.readAsDataURL(file)
  })
}

export function isColorToLight(color?: string | null) {
  if (!color) {
    return false
  }
  const hex = color.replace('#', '')
  const c_r = parseInt(hex.substring(0, 2), 16)
  const c_g = parseInt(hex.substring(2, 4), 16)
  const c_b = parseInt(hex.substring(4, 6), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness > 155
}

const sleep = (delay = 1000) => {
  return new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, delay)
  })
}

function getArrayDepth(value: any): number {
  return Array.isArray(value) ? 1 + Math.max(0, ...value.map(getArrayDepth)) : 0
}

function toSortAntd(key?: string | null): SortOrder | undefined {
  if (!key) return
  if (['asc', 'desc'].includes(key)) {
    return key === 'asc' ? 'ascend' : 'descend'
  } else if (['ascend', 'descend'].includes(key)) {
    return key as SortOrder
  }
}
toSortAntd.reverse = (key?: SortOrder | null): 'asc' | 'desc' | undefined => {
  if (!key) return
  if (['ascend', 'descend'].includes(key)) {
    return key === 'ascend' ? 'asc' : 'desc'
  }
  if (['asc', 'desc'].includes(key)) {
    return key as 'asc' | 'desc'
  }
}

export const utils = {
  showImageBase64,
  getArrayDepth,
  sleep,
  /* istanbul ignore next */
  getQueryDataByQueryKey: <T = any>(key: QueryKey) => {
    /* istanbul ignore next */
    return queryClient.getQueryData(key) as T
  },
  booleanify: (value: any): boolean => {
    if (typeof value === 'boolean') return value
    if (['true', 'True', 'TRUE', '1', 1].includes(value)) return true
    if (['false', 'False', 'FALSE', '0', 0].includes(value)) return false
    return !!value
  },
  toSortAntd,
  getNowDate: {
    BE_String: () => DateConfig.date().format(FORMAT_BACKEND_DATE),
    FE_String: () => DateConfig.date().format(FORMAT_DATE),
    moment: DateConfig.date,
  },
  getBase64FromFile: readInputFile,
  getImageUrlFromBE_Url(val?: string | null) {
    if (!val) return undefined
    const x = [BASE_API_URL] as string[]
    if (val.startsWith('api/')) x.push(val.replace('api', ''))
    else if (val.startsWith('/api/')) x.push(val.replace('/api', ''))
    else x.push(val)
    return x.join('')
  },
  defaultImageFallbackUrl: '/images/no-image.png',
}
