import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { ButtonProps, InputProps, InputRef } from 'antd'
import { Button, Input } from 'antd'
import { readInputFile } from 'v2source/tools'

type FileUploadDefaultProps = {
  inputProps?: InputProps
  buttonProps?: ButtonProps
  hide?: boolean
  current?: File
}

export default function FileUpload({
  inputProps,
  buttonProps,
  hide,
  current,
}: FileUploadDefaultProps) {
  const { t } = useTranslation()
  const ref = useRef<InputRef>(null)
  return (
    <div className={`inline-flex ${hide ? 'hidden' : ''}`}>
      <Input
        readOnly
        value={current?.name}
        className="w-full"
        suffix
        {...inputProps}
        type="file"
        ref={ref}
      />
      <Button
        htmlType="button"
        {...buttonProps}
        className={`custom-default-btn ${buttonProps?.className || ''}`}
        onClick={() => {
          ref.current?.input?.click()
        }}
      >
        {buttonProps?.children || t('upload')}
      </Button>
    </div>
  )
}

type DefaultOptions = { triggerOnChange?: boolean }

FileUpload.useFileUploadHook = ({
  onChange,
  accept,
  validate,
}: {
  onChange?: (data?: { file?: File; base64?: string }) => void
  accept?: string[]
  validate?: (file: File) => boolean
}) => {
  const [file, setFile] = useState<{ file?: File; base64?: string }>()
  const ref = useRef<HTMLInputElement>(null)
  return {
    ...file,
    setFile: async (file?: File, options?: DefaultOptions) => {
      if (validate && file && !validate(file)) return
      const base64 = file ? ((await readInputFile(file)) as string) : undefined
      setFile({ file, base64 })
      if (options?.triggerOnChange) {
        if (file) {
          onChange?.({ file, base64 })
        } else {
          onChange?.()
        }
      }
    },
    clear: (options?: DefaultOptions) => {
      setFile({})
      if (options?.triggerOnChange) {
        onChange?.()
      }
    },
    openFileDialog: () => {
      ref.current?.click()
    },
    component: (
      <input
        type="file"
        accept={accept?.join(',')}
        className="hidden"
        data-testid="native-file-upload"
        ref={ref}
        onChange={async (e) => {
          if (e.target.files?.[0]) {
            if (
              validate &&
              e.target.files?.[0] &&
              !validate(e.target.files?.[0])
            )
              return
            const base64 = (await readInputFile(e.target.files?.[0])) as string
            setFile({ file: e.target.files?.[0], base64 })
            if (onChange) {
              onChange({ file: e.target.files?.[0], base64 })
            }
          } else {
            setFile({})
            onChange?.()
          }
        }}
      />
    ),
  }
}
