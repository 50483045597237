import { useCreation, useMemoizedFn } from 'ahooks'
import { isNumber } from 'lodash'

import useContract from 'data/useContract'
import useCurrency from 'data/useCurrency'

import { JAPAN_CURRENCY_ID } from 'utils/constants'

import { getNumberCurrencyFormatter } from './numberCurrencyUtils'

function getStateTime(
  staleTime?: LDXCommon.NumberCurrencyFormaterProps['staleTime'],
  key?: 'currencyMaster' | 'contractMaster',
) {
  if (!staleTime) return undefined
  if (isNumber(staleTime)) return staleTime
  return key ? staleTime[key] : undefined
}

type UseSearchContractMasterOptions = Parameters<typeof useContract>[1]

function useSearchContractMaster(options?: UseSearchContractMasterOptions) {
  return useContract(
    {
      fields: [
        'rounding_method',
        'rounding_method_purchase',
        'rounding_method_sales',
        'ratio_decimal_places',
        'qty_decimal_places',
        'currency_decimal_places',
      ],
    },
    {
      // because contract master rarely changes
      staleTime: 5 * 60 * 1000,
      refetchOnMount: false,
      ...(options || {}),
    },
  )
}

export function useNumberCurrencyAdjustment(
  params?: Omit<
    LDXCommon.NumberCurrencyFormaterProps,
    'minimumFractionDigits'
  > & {
    minimumFractionDigits?: number | boolean
    useContractDecimalPlaces?: boolean
  },
) {
  const {
    currencyId = JAPAN_CURRENCY_ID,
    roundingMethodUsage = 'default',
    roundingMethod,
    useRounding = true,
    decimal = true,
    decimalPlaces,
    minimumFractionDigits: _minimumFractionDigits,
    displayFormatFallbackValue,
    staleTime,
    useContractDecimalPlaces,
  } = params || {}
  const { data: currencyDataList = [], isFetching: isFetchingCurrency } =
    useCurrency(
      {
        field: ['decimal_places', 'display_decimal_digits', 'symbol', 'name'],
        domain: [['id', '=', currencyId]],
      },
      {
        enabled: !!currencyId && decimal && !decimalPlaces,
        refetchOnMount: false,
      },
    )

  const currencyData = currencyDataList.find((x) => x.id === currencyId)
  const jpyCurrencyData = currencyDataList.find(
    (x) => x.id === JAPAN_CURRENCY_ID,
  )

  const { data: contract, isFetching: isFetchingContract } =
    useSearchContractMaster({
      enabled: (!roundingMethod || useContractDecimalPlaces) && useRounding,
      staleTime: getStateTime(staleTime, 'contractMaster'),
    })
  const getRoundingContract = useMemoizedFn(() => {
    if (roundingMethodUsage === 'sales') {
      return contract?.rounding_method_sales
    }
    if (roundingMethodUsage === 'purchase') {
      return contract?.rounding_method_purchase
    }
    return contract?.rounding_method
  })
  const decimal_places = useContractDecimalPlaces
    ? contract?.currency_decimal_places
    : decimalPlaces ?? currencyData?.decimal_places ?? 0
  const rounding_method = useRounding
    ? roundingMethod ?? getRoundingContract() ?? 'rounding'
    : undefined

  const getMinimumFractionDigits = useMemoizedFn((decimalPlaces: number) => {
    if (typeof _minimumFractionDigits === 'boolean') {
      return _minimumFractionDigits ? decimalPlaces : 0
    }
    return _minimumFractionDigits
  })

  const minimumFractionDigits = getMinimumFractionDigits(decimal_places)

  const numberCurrencyConfig = {
    decimalPlaces: decimal ? decimal_places : 0,
    roundingMethod: rounding_method as any,
    displayFormatFallbackValue,
    minimumFractionDigits: minimumFractionDigits ?? decimal_places,
  }
  const localFormatter = getNumberCurrencyFormatter(numberCurrencyConfig)

  const jpyCurrencyAdjustment = useCreation(() => {
    const jpy_decimal_places =
      decimalPlaces ?? jpyCurrencyData?.decimal_places ?? 0
    const jpyMinimumDecimal = getMinimumFractionDigits(jpy_decimal_places)
    const fs = getNumberCurrencyFormatter({
      decimalPlaces: decimal ? jpy_decimal_places : 0,
      roundingMethod: rounding_method as any,
      displayFormatFallbackValue,
      minimumFractionDigits: jpyMinimumDecimal ?? jpy_decimal_places,
    })
    return {
      ...fs,
      formatToDisplay(value: any) {
        return fs.formatter(value)
      },
      adjustCurrency: fs.adjust,
    }
  }, [jpyCurrencyData, decimal, rounding_method])

  return {
    isFetching: isFetchingContract || isFetchingCurrency,
    jpyCurrencyAdjustment,
    minimumFractionDigits,
    jpyCurrencyData,
    currencyData,
    decimal_places,
    rounding_method,
    formatter: localFormatter.formatter,
    parse: localFormatter.parse,
    getNumberCurrencyFormatter(
      decimalPlaces?: number,
      displayFormatFallbackValue?: any,
    ) {
      const x = getNumberCurrencyFormatter({
        decimalPlaces,
        roundingMethod: rounding_method as any,
        displayFormatFallbackValue,
        minimumFractionDigits: minimumFractionDigits ?? decimal_places,
      })
      return {
        ...x,
        adjustCurrency: x.adjust,
      }
    },
    numberCurrencyConfig,
    formatToDisplay(value: any) {
      return localFormatter.formatter(value)
    },
    /**
     *
     * @param value number | string
     * @method rounding_method and decimal_places will be used
     * @returns rounded value
     */
    adjustCurrency: localFormatter.adjust,
  }
}
useNumberCurrencyAdjustment.searchContractMaster = useSearchContractMaster
useNumberCurrencyAdjustment.getFormatter = getNumberCurrencyFormatter
useNumberCurrencyAdjustment.getCurrencyAdjustment = (
  config: Parameters<typeof getNumberCurrencyFormatter>[0],
) => {
  return getNumberCurrencyFormatter(config).adjust
}
useNumberCurrencyAdjustment.isJPYCurrency = (currencyId?: number) =>
  !currencyId || currencyId === JAPAN_CURRENCY_ID
