/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-types */
import type { Dispatch, ReactElement, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { QueryKey } from 'react-query'
import { useIsFetching, useIsMutating, useQuery } from 'react-query'

import { useRouter } from 'next/router'

import {
  useCreation,
  useDebounce,
  useDeepCompareEffect,
  useMemoizedFn,
  useSafeState,
} from 'ahooks'
import {
  Select as AntSelect,
  TreeSelect as AntTreeSelect,
  Divider,
  Radio,
  Spin,
  Tag,
} from 'antd'
import { clsx } from 'clsx'
import { get, isNil, omit, uniq, uniqBy } from 'lodash'
import ApiCall from 'services/ApiCall'
import Button from 'v2source/components/Buttons'
import { CodeNameColumnDisplay } from 'v2source/components/Display/GeneralTemplates'
import { Input } from 'v2source/components/Inputs'
import { queryClientHelpers } from 'v2source/services/api/queryClientHelpers'
import {
  convertDataToAntSelectOptions,
  filterSelectOptionLabel,
  utils,
} from 'v2source/tools'

import { queryClient } from 'components/Provider'

function LoaderSpinner(props: { color?: string; size?: number }) {
  return (
    <Spin
      size="small"
      wrapperClassName="loader-spinner"
      style={{ color: props.color }}
    />
  )
}

function removeUnnecessaryProps(props: LDXCommon.SelectProps) {
  return omit(
    {
      ...(props ?? {}),
      labelRender: props.labelRender
        ? (args: any) => {
            const obj = props?.options?.find((x) => x.value === args.value)
            return props?.labelRender?.({ ...args, ...obj })
          }
        : undefined,
    },
    [
      'asText',
      'formProps',
      'prefixLabelField',
      'defaultEmptyValue',
      'tagRender',
      'textLink',
      'customLabelRender',
    ],
  ) as any
}

function normalizeSelectProps<T = any, Value = any>(
  props: LDXCommon.SelectProps<T, Value>,
): Parameters<typeof AntSelect>[0] {
  if (!props?.prefixLabelField && !props?.customLabelRender)
    return removeUnnecessaryProps(props)
  const { options = [], prefixLabelField, customLabelRender } = props
  const renderCodeName = (
    data: { col1: string; col2: string },
    selected?: boolean,
  ) => {
    return (
      <CodeNameColumnDisplay
        code={data.col1}
        name={data.col2}
        containerClassName={selected ? 'is-selected' : ''}
      />
    )
  }

  function buildOptions() {
    return options.map((k) => {
      const col1 = prefixLabelField ? get(k.original, prefixLabelField) : ''
      const col2 = k.label
      const displayText = prefixLabelField
        ? col1 &&
          col2 &&
          !!String(col1)?.trim()?.length &&
          !!String(col2)?.trim()?.length
          ? [col1 ? `[${col1}]` : '', col2 || ''].join(' ')
          : [String(col1)?.trim(), String(col2)?.trim()]
              .filter((x) => !!x)
              .join(' ')
        : col2
      const isSelected = props.value === k.value
      const label = prefixLabelField
        ? renderCodeName({ col1, col2 }, isSelected)
        : col2
      const custom_renderer = customLabelRender?.({
        data: k.original,
        displayText,
        label,
        isSelected,
      })
      return {
        displayText,
        label,
        original: k.original,
        ...(custom_renderer || {}),
        ...omit(k, ['original', 'label']),
      }
    })
  }
  const newOptions = buildOptions() as any[]
  return {
    ...(props.mode
      ? {
          tagRender(tagProps) {
            const opt = newOptions.find((x) => x.value === tagProps.value)
            if (props.tagRender) {
              return props.tagRender({
                ...tagProps,
                displayText: opt?.displayText,
                original: opt?.original,
              })
            }
            return (
              <Tag
                key={tagProps.value}
                className="m-0.5"
                closable={!props.disabled && !props.asText && tagProps.closable}
                onClose={tagProps.onClose}
              >
                <span className="selector-tag-text-display">
                  {opt?.displayText}
                </span>
              </Tag>
            )
          },
        }
      : {}),
    filterOption: (input: string, option: any) => {
      return (
        option?.displayText
          ?.toString()
          .toLowerCase()
          .indexOf(input?.toLowerCase()) >= 0
      )
    },
    ...removeUnnecessaryProps(props),
    value: props.mode
      ? props.value
      : newOptions.find((x) => x.value === props.value)?.displayText,
    options: newOptions,
    popupClassName: [
      props.popupClassName ?? '',
      'select-with-prefix-display-popup',
    ].join(' '),
  }
}
const isEmptyArray = (arr: any) => Array.isArray(arr) && arr.length === 0

function DisplayTextAsLink({
  asLink,
  url,
  newTab,
  children,
}: {
  asLink?: boolean
  url?: string
  newTab?: boolean
  children?: React.ReactNode
}) {
  const navigate = useRouter()
  const onClick = useMemoizedFn(() => {
    if (!asLink || !url) return
    if (newTab) {
      window.open(url, '_blank')
    } else {
      navigate.push(url)
    }
  })
  return (
    <span
      className={clsx('select-value-as-text block', asLink && 'as-link')}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

function CustomSelect<T = any, Value = any>(
  props: LDXCommon.SelectProps<T, Value>,
) {
  const { t } = useTranslation()
  const newProps = normalizeSelectProps(props)
  if (props.asText) {
    if (isNil(props.value) || (!!props.mode && isEmptyArray(props.value)))
      return <span className="block">{props.defaultEmptyValue}</span>
    if (props.mode) {
      return (
        <div className="flex flex-wrap">
          {newProps.options
            ?.filter((x) => ((newProps.value || []) as any).includes(x.value))
            ?.map((x) => newProps.tagRender?.(x as any))}
        </div>
      )
    }
    const opt = newProps.options?.find((x) => x.value === props.value)
    const { asLink, url, newTab } =
      typeof props.textLink === 'object'
        ? { asLink: true, ...props.textLink }
        : { asLink: !!props.textLink, url: props.textLink, newTab: false }

    return (
      <DisplayTextAsLink asLink={asLink} url={url} newTab={newTab}>
        {opt?.label}
      </DisplayTextAsLink>
    )
  }

  return (
    <AntSelect
      placeholder={props.disabled ? '' : t('select')}
      filterOption={filterSelectOptionLabel}
      {...omit(newProps, ['labelRender'])}
      className={
        newProps.className
          ? clsx(
              newProps.className,
              props.prefixLabelField ? 'select-with-prefix-display' : undefined,
            )
          : 'w-full'
      }
    />
  )
}

export function Select<T = any, Value = any>({
  defaultEmptyValue = null,
  ...props
}: LDXCommon.SelectProps<T, Value>) {
  const { t } = useTranslation()
  return (
    <CustomSelect
      className="w-full flex-shrink-0"
      placeholder={props.disabled ? '' : t('select')}
      filterOption={filterSelectOptionLabel}
      getPopupContainer={(trigger) => trigger.parentNode}
      defaultEmptyValue={defaultEmptyValue}
      {...props}
      onChange={(...args) => {
        const [v, ...rest] = args
        // transform undefined or empty value to defaultEmptyValue
        return props?.onChange?.(
          v === undefined ? defaultEmptyValue : v,
          ...rest,
        )
      }}
    />
  )
}

const DebounceSelectContext = createContext({} as Record<string, any>)
type DebounceSelectContextDataType<DataModel = any, T extends object = {}> = {
  selectedData?: DataModel
} & T
function useDebounceSelectContext<DataModel = any, T extends object = {}>() {
  return useContext(DebounceSelectContext) as {
    data: DebounceSelectContextDataType<DataModel, T>
    setData: Dispatch<
      SetStateAction<DebounceSelectContextDataType<DataModel, T>>
    >
  }
}

function DebounceSelectComponent<T extends Record<string, any> = any>({
  name,
  debounceTimeout = 800,
  selectedKey = 'id',
  searchKey = 'name',
  limit = 20,
  generateSearchDomain,
  optionLabelKey,
  optionValueKey,
  currentValue: _currentValue,
  fields,
  queryOptions,
  selectedQueryOptions,
  keyForSelectedDataToSave,
  getSelectedDataFromRawResponse,
  asText,
  onSelectedDataLoaded,
  onSelectedData,
  disableSelectedRequest,
  initialTouched = false,
  getSelectedExtraDomain,
  getSelectedExtraParams,
  onOptions,
  sort,
  customValueToDisplay = (val) => val,
  optionConverter = convertDataToAntSelectOptions,
  alwaysFetchSelectedData,
  overwriteHookFnSearchParameters,
  enableCreateNewData = false,
  searchStaticDomain = [],
  fetchConfig,
  ...restProps
}: LDXCommon.SelectDebounceProps<T>): ReactElement | JSX.Element {
  const { t } = useTranslation()
  const [realSearchText, setSearchText] = useState('')
  const context = useDebounceSelectContext()
  const searchText = useDebounce(realSearchText, { wait: debounceTimeout })
  const [isTouched, setTouched] = useState(initialTouched)
  const createNewDataValue = useRef<string>('')

  const currentValue = restProps?.value || _currentValue

  const setSelectedQueryData = (dataToSave: any) => {
    if (keyForSelectedDataToSave) {
      queryClient.setQueryData(keyForSelectedDataToSave, dataToSave)
    }
  }

  const searchFields = uniq([
    ...(fields || []),
    searchKey || (name as string),
    ...(restProps.prefixLabelField ? [restProps.prefixLabelField] : []),
  ])

  const searchFieldKey = searchKey || name

  function buildSearchDomain() {
    if (generateSearchDomain) return generateSearchDomain(searchText)
    if (searchText) {
      return restProps.prefixLabelField
        ? [
            '|',
            [restProps.prefixLabelField, 'ilike', searchText],
            [searchFieldKey, 'ilike', searchText],
          ]
        : [[searchFieldKey, 'ilike', searchText]]
    }
    return []
  }

  const currentValueIsNewData =
    !!enableCreateNewData &&
    !!createNewDataValue.current &&
    currentValue === createNewDataValue.current

  const _searchParameters = {
    limit,
    fields:
      Array.isArray(fields) && fields.length === 0 ? fields : searchFields,
    domain: [...searchStaticDomain, ...buildSearchDomain()],
    sort: searchText && !sort ? `${searchFieldKey} asc` : sort,
  }
  const searchParameters = {
    ..._searchParameters,
    ...overwriteHookFnSearchParameters?.(_searchParameters),
  }
  const search = useQuery<any, any>({
    queryKey: [name, searchParameters],
    queryFn: () => {
      if (typeof fetchConfig === 'function')
        return fetchConfig(searchParameters).then((r) => r.result.records)
      return ApiCall._generateCrud(fetchConfig.model)
        .search(searchParameters as any)
        .then((r) => r.records)
    },
    ...queryOptions,
    enabled:
      (queryOptions?.enabled ?? true) && !restProps.disabled && isTouched,
  })

  function isSelectedNotInSearch() {
    if (alwaysFetchSelectedData) return true
    const defaultValue = ['multiple', 'tags'].includes(restProps.mode as any)
      ? []
      : null
    if (Array.isArray(currentValue || defaultValue)) {
      const _ids =
        search.data?.map((x) => x[optionValueKey || selectedKey]) || []
      return !currentValue.every((x: any) => _ids.includes(x))
    }
    return !search.data?.find(
      (x) => x[optionValueKey || selectedKey] === currentValue,
    )
  }

  const selectedParams = {
    limit: ['multiple', 'tags'].includes(restProps.mode as any)
      ? (currentValue || []).length
      : 1,
    fields: searchFields,
    defaultDomain: [],
    domain: [
      [
        selectedKey || name,
        Array.isArray(currentValue) ? 'in' : '=',
        currentValue,
      ],
      ...(getSelectedExtraDomain?.() || []),
    ],
    ...(getSelectedExtraParams?.() || {}),
  }

  const selected = useQuery<any, any>({
    queryKey: [name, selectedParams],
    queryFn: () => {
      if (typeof fetchConfig === 'function')
        return fetchConfig(selectedParams).then((r) => r.result.records)
      return ApiCall._generateCrud(fetchConfig.model)
        .search(selectedParams as any)
        .then((r) => r.records)
    },
    ...(selectedQueryOptions as any),
    enabled:
      !!currentValue &&
      // can load selected if search has limit
      !!limit &&
      !currentValueIsNewData &&
      !disableSelectedRequest &&
      (selectedQueryOptions?.enabled ?? true) &&
      isSelectedNotInSearch(),
    onSuccess: (resp) => {
      let respData
      if (resp.result?.records) {
        setSelectedQueryData(resp.result?.records?.[0])
        respData = resp.result?.records?.[0]
      } else if (resp?.result) {
        if (Array.isArray(resp.result)) {
          respData = resp.result?.[0]
        } else {
          respData = resp.result
        }
      }

      const isMultiple = ['multiple', 'tags'].includes(restProps.mode as any)

      onSelectedDataLoaded?.(isMultiple ? resp.result?.records || [] : respData)

      if (keyForSelectedDataToSave) {
        if (getSelectedDataFromRawResponse) {
          setSelectedQueryData(getSelectedDataFromRawResponse(resp))
        } else {
          if (respData) {
            setSelectedQueryData(respData)
          }
        }
      }

      selectedQueryOptions?.onSuccess?.(resp)
    },
  })

  const options = optionConverter(
    [
      ...((currentValue && !currentValueIsNewData ? selected.data : []) || []),
      ...(search.data || []),
    ],
    optionLabelKey || searchKey,
    optionValueKey || selectedKey,
  )
  const currentValueIsInOptions = !!options.find(
    (x) => x.value === currentValue,
  )

  function getValue() {
    const defaultValue = ['multiple', 'tags'].includes(restProps.mode as any)
      ? [...(restProps?.value || [])]
      : null
    if (currentValueIsInOptions) return customValueToDisplay(currentValue)
    return selected.isFetching
      ? defaultValue
      : customValueToDisplay(currentValue)
  }

  function getOptions() {
    const opts = onOptions
      ? onOptions(uniqBy(options, 'value'))
      : uniqBy(options, 'value')
    if (currentValueIsInOptions) return opts
    return search.isFetching ? [] : opts
  }

  const optionList = getOptions()
  const selectedValue = getValue()
  const selectedData = restProps.mode
    ? optionList.filter((x) => (selectedValue as any[])?.includes(x.value))
    : optionList.find((x) => x.value === selectedValue)

  // handle selectedData
  useDeepCompareEffect(() => {
    if (Array.isArray(selectedData) ? selectedData?.length : selectedData) {
      const _datas = (
        Array.isArray(selectedData)
          ? selectedData?.map((x) => x.original)
          : selectedData?.original
      ) as any
      onSelectedData?.(_datas)
      context?.setData?.((prev: any) => ({ ...prev, selectedData: _datas }))
    } else {
      context?.setData?.((prev: any) => ({ ...prev, selectedData: undefined }))
    }
  }, [JSON.stringify(selectedData)])

  const newDataOption = useCreation(() => {
    return {
      label: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('common:create_new_option', {
              searchText,
            }),
          }}
        />
      ) as any,
      value: searchText,
      __is_new_data: true,
      ...(typeof enableCreateNewData === 'object' ? enableCreateNewData : {}),
    }
  }, [searchText, enableCreateNewData])

  if (restProps.disabled && asText && selected.isFetching) {
    return <Spin size="small" />
  }

  const _selectedOnlyOpts = optionList.filter((x) =>
    restProps.mode
      ? restProps?.value?.includes(x.value)
      : x.value === restProps?.value,
  )

  if (enableCreateNewData) {
    if (currentValueIsNewData && !!createNewDataValue.current) {
      optionList.unshift({
        label: (
          <span
            dangerouslySetInnerHTML={{
              __html: t('common:create_new_option', {
                searchText: createNewDataValue.current,
              }),
            }}
          />
        ) as any,
        value: createNewDataValue.current,
        ...(typeof enableCreateNewData === 'object' ? enableCreateNewData : {}),
      })
    }
    if (searchText.length) {
      optionList.push(newDataOption)
    }
  }

  const isFetching = search.isFetching || selected.isFetching

  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      dropdownRender={
        isFetching && !!_selectedOnlyOpts.length
          ? (menu) => {
              return (
                <>
                  {menu}
                  <div className="p-2">
                    <LoaderSpinner color="#1c7aff" size={6} />
                  </div>
                </>
              )
            }
          : undefined
      }
      {...restProps}
      asText={asText}
      onSearch={setSearchText}
      value={selectedValue}
      loading={isFetching || restProps?.loading}
      onClear={() => {
        setSearchText('')
        restProps?.onClear?.()
      }}
      onChange={(...args) => {
        createNewDataValue.current = ''
        if (enableCreateNewData && args?.[1]?.__is_new_data) {
          createNewDataValue.current = args?.[0]
        }
        setSearchText('')
        restProps?.onChange?.(...args)
      }}
      options={uniqBy(
        isFetching
          ? [
              ..._selectedOnlyOpts,
              ...(enableCreateNewData && !!searchText ? [newDataOption] : []),
            ]
          : optionList,
        'value',
      )}
      onFocus={(event) => {
        restProps?.onFocus?.(event)
        if (!isTouched) setTouched(true)
      }}
      notFoundContent={
        search.isFetching ? (
          <div className="p-2">
            {/* <Spin /> */}
            <LoaderSpinner color="#1c7aff" size={6} />
          </div>
        ) : (
          restProps.notFoundContent
        )
      }
    />
  )
}
function DebounceSelect<T extends Record<string, any> = any>({
  sharedContext,
  ...props
}: LDXCommon.SelectDebounceProps<T>) {
  const [data, setData] = useSafeState({} as Record<string, any>)
  if (!sharedContext) {
    return <DebounceSelectComponent {...props} />
  }
  useEffect(() => {
    if (typeof sharedContext === 'object') {
      setData(sharedContext)
    }
  }, [sharedContext])
  return (
    <DebounceSelectContext.Provider value={{ data, setData }}>
      <DebounceSelectComponent {...props} />
    </DebounceSelectContext.Provider>
  )
}
DebounceSelect.useContext = useDebounceSelectContext
DebounceSelect.buildOptionModelForNewData = function buildOptionModelForNewData(
  value: any,
) {
  return {
    label: value,
    value,
    __is_new_data: true,
  }
}
DebounceSelect.getSelectedQueryData =
  function getSelectedQueryDataFromSelectedDebounce(key: QueryKey) {
    return utils.getQueryDataByQueryKey(key)
  }
/**
 * @features multiple
 * @argument searchKey => field to filter, domain = [[searchKey, 'ilike', searchText]]
 * @argument selectedKey => field to search for selected current value and also use for value in select
 * @argument optionLabelKey => field in search result use for display in select
 * @argument optionValueKey => field in search result use for value in select
 * @abstract keyForSelectedDataToSave => is use to save the result of selected data, so that you can access it everywhere
 * call it like Select.Debounce.getSelectedData(key)
 * allowed response format is (result.records[], result[], result)
 */
Select.Debounce = DebounceSelect

Select.useDebounceHook = function useDebounceSelectHook<
  T extends Record<string, any> = any,
>({
  queryKeyIdentifier,
  useComponent = true,
  ...props
}: LDXCommon.SelectDebounceProps<T> & {
  queryKeyIdentifier: string
  useComponent?: boolean
}) {
  const queryKey = useCreation(() => {
    return queryKeyIdentifier
  }, [])
  const selectedQueryKey = [queryKey, 'selected_data'].join('_')
  const searchQueryKey = [queryKey, 'search'].join('_')

  const searchIsFetching =
    useIsFetching(queryClientHelpers.getQueryFilterByQueryKey(searchQueryKey)) >
    0
  const searchIsMutating =
    useIsMutating(queryClientHelpers.getQueryFilterByQueryKey(searchQueryKey)) >
    0
  const selectedIsFetching =
    useIsFetching(
      queryClientHelpers.getQueryFilterByQueryKey(selectedQueryKey),
    ) > 0
  const selectedIsMutating =
    useIsMutating(
      queryClientHelpers.getQueryFilterByQueryKey(selectedQueryKey),
    ) > 0

  return {
    queryHelpers: {
      search: {
        ...queryClientHelpers(searchQueryKey),
        isFetching: searchIsFetching,
        isMutating: searchIsMutating,
      },
      selected: {
        ...queryClientHelpers(selectedQueryKey),
        isFetching: selectedIsFetching,
        isMutating: selectedIsMutating,
      },
    },
    component: useComponent ? (
      <DebounceSelect<T>
        {...props}
        queryOptions={{ ...props.queryOptions, queryKey: [searchQueryKey] }}
        selectedQueryOptions={{
          ...props.selectedQueryOptions,
          queryKey: [selectedQueryKey],
        }}
      />
    ) : (
      <div />
    ),
  }
}

export type DropdownRenderAddItemDefaultProps = {
  onAdd?: (value: string) => Promise<any>
  href?: string
  component?: ReactElement
  buttonComponent?: ReactElement
}

function SelectDropdownRenderAddSingleInputItemComponent({
  onAdd,
}: {
  onAdd?: DropdownRenderAddItemDefaultProps['onAdd']
}) {
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  return (
    <div className="flex flex-col gap-1 p-2">
      <Input
        id="input-add-item"
        value={input}
        disabled={loading}
        onChange={(e) => setInput(e.target.value)}
      />
      <Button
        id="btn-add-item"
        htmlType="button"
        block
        type="primary"
        loading={loading}
        onClick={async () => {
          setLoading(true)
          try {
            await onAdd?.(input)
          } finally {
            setLoading(false)
          }
          setInput('')
        }}
      >
        {t('addItem')}
      </Button>
    </div>
  )
}

function DropdownRenderWithAddItemSingleInput(
  options?: DropdownRenderAddItemDefaultProps,
) {
  const { t } = useTranslation()
  function renderHrefButton() {
    if (!options?.href) return null
    if (options?.buttonComponent) return options.buttonComponent
    return (
      <div className="w-full p-2">
        <Button
          htmlType="button"
          block
          type="primary"
          target="_blank"
          href={options?.href}
        >
          {t('addItem')}
        </Button>
      </div>
    )
  }

  return function handler(menus: any) {
    return (
      <div className="flex flex-col">
        {menus}
        <Divider className="my-0" />
        {options?.onAdd
          ? options?.component || (
              <SelectDropdownRenderAddSingleInputItemComponent
                onAdd={options?.onAdd}
              />
            )
          : renderHrefButton()}
      </div>
    )
  }
}

DropdownRenderWithAddItemSingleInput.Component =
  SelectDropdownRenderAddSingleInputItemComponent
Select.dropdownRenderWithAddItem = {
  SingleInput: DropdownRenderWithAddItemSingleInput,
}

Select.TreeSelect = function TreeSelect(props: LDXCommon.TreeSelectProps) {
  const { t } = useTranslation()
  return (
    <AntTreeSelect
      className="w-full"
      treeDefaultExpandAll
      treeNodeFilterProp="title"
      placeholder={props.disabled ? '' : t('select')}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    />
  )
}

Select.RadioGroup = function SelectRadioGroup(
  props: LDXCommon.RadioGroupProps,
) {
  if (props.asText) {
    if (!props.options) return props.value
    const selected = props.options.find(
      (x: any) => x.value === props.value,
    ) as any
    return selected?.label
  }
  return <Radio.Group {...props} />
}

Select.Option = AntSelect.Option
Select.OptGroup = AntSelect.OptGroup
