import moment from 'moment'

import { Input } from 'components/Templates/Input'
import convertDate from 'helpers/convertDateFilter'
import { parseNumber } from 'utils'

type Year = {
  title: string
  value: number
}

export function GenerateYears(from: number, to: number): Year[] {
  const result: Year[] = []
  for (let i = from; i <= to; i += 1) {
    result.push({
      title: `${i}`,
      value: i,
    })
  }
  return result
}

export function Truncate(str: string, len: number): string {
  if (!str) return str
  return str.length <= len ? str : `${str.substr(0, len)}...`
}

export function Price(p?: number): string {
  return (
    p?.toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }) ?? ''
  )
}

export function Rate(p?: number): string {
  if (p === undefined) return '%'
  return `${p * 100}%`
}
export function GenerateMonthsFromBudget(budget, func_build_element) {
  const result = []
  if (budget) {
    const current_time = moment(budget.start).startOf('month')
    const end_time = moment(budget.end).endOf('month')
    let index = 1
    while (current_time <= end_time) {
      const element = func_build_element(current_time, index)
      index += 1
      result.push(element)
      current_time.add(1, 'month')
    }
  }

  return result
}

export default GenerateYears
export function Sum(arr) {
  let total = 0
  arr.map((item) => {
    total += item
    return item
  })
  return total
}

export function getNumberByValue(value) {
  const initials = [
    {
      title: 'one',
      value: 1,
    },
    {
      title: 'two',
      value: 2,
    },
    {
      title: 'three',
      value: 3,
    },
    {
      title: 'four',
      value: 4,
    },
    {
      title: 'five',
      value: 5,
    },
    {
      title: 'six',
      value: 6,
    },
    {
      title: 'seven',
      value: 7,
    },
    {
      title: 'eight',
      value: 8,
    },
    {
      title: 'nine',
      value: 9,
    },
    {
      title: 'ten',
      value: 10,
    },
    {
      title: 'eleven',
      value: 11,
    },
    {
      title: 'twelve',
      value: 12,
    },
    {
      title: 'thirteen',
      value: 13,
    },
    {
      title: 'fourteen',
      value: 14,
    },
    {
      title: 'fifteen',
      value: 15,
    },
  ]
  const objectFindedValue = initials.find((initial) => initial.value === value)
  return objectFindedValue?.title
}

export function getValueByNumber(value) {
  const initials = [
    {
      title: 'one',
      value: 1,
    },
    {
      title: 'two',
      value: 2,
    },
    {
      title: 'three',
      value: 3,
    },
    {
      title: 'four',
      value: 4,
    },
    {
      title: 'five',
      value: 5,
    },
    {
      title: 'six',
      value: 6,
    },
    {
      title: 'seven',
      value: 7,
    },
    {
      title: 'eight',
      value: 8,
    },
    {
      title: 'nine',
      value: 9,
    },
    {
      title: 'ten',
      value: 10,
    },
    {
      title: 'eleven',
      value: 11,
    },
    {
      title: 'twelve',
      value: 12,
    },
    {
      title: 'thirteen',
      value: 13,
    },
    {
      title: 'fourteen',
      value: 14,
    },
    {
      title: 'fifteen',
      value: 15,
    },
  ]
  const objectFindedValue = initials.find((initial) => initial.title === value)
  return objectFindedValue?.value
}

export const filterOption = (input, option) =>
  option.label?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0

export const parseSearch = (values) =>
  Object.keys(values)
    .filter((f) => values[f])
    .map((key) => {
      if (Array.isArray(values[key])) {
        return values[key].map((v, i) => [
          key,
          i === 0 ? '>=' : '<=',
          i === 0
            ? convertDate.convertStartDate(v)
            : convertDate.convertEndDate(v),
        ])
      }
      if (!Number.isNaN(Number(values[key])) && !key.includes('_no')) {
        return [key, '=', Number(values[key])]
      }
      return [key, 'ilike', values[key]]
    })
    .reduce((prev, curr) => {
      if (Array.isArray(curr) && curr.length > 1) {
        if (Array.isArray(curr[0])) {
          return [...prev, ...curr]
        }

        return [...prev, curr]
      }
      return [...prev, curr]
    }, [])

export const isError = (object: any): object is ResponseError => {
  if (typeof object === 'undefined') {
    return true
  }
  return 'error' in object
}

export const showImageBase64 = (imageSource: string) => {
  if (imageSource) {
    return `data:image/png;base64,${imageSource}`
  }

  return '/images/no-image.png'
}

export const getLocalTimeZoneName = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const sumDataWithDecimalAdjustment = (arr: number[]) => {
  return Input.Number.decimalAdjustment.sum(
    ...(arr?.map((x) => parseNumber(x)) || []),
  )
}

export const sumByDataWithDecimalAdjustment = (
  arr: any[],
  field: string | ((curr: any) => number),
) => {
  return Input.Number.decimalAdjustment.sum(
    ...(arr?.map((x) =>
      parseNumber(typeof field === 'string' ? x[field] : field(x)),
    ) || []),
  )
}
