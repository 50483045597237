import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseCircleFilled, SwapRightOutlined } from '@ant-design/icons'
import { useObservable } from '@legendapp/state/react'
import { useCreation, useMemoizedFn, useSafeState } from 'ahooks'
import {
  Checkbox as AntCheckbox,
  Input as AntInput,
  InputNumber as AntInputNumber,
} from 'antd'
import { clsx } from 'clsx'
import { isUndefined, omit } from 'lodash'
import { useNumberCurrencyAdjustment } from 'v2source/services/hooks/useNumberCurrencyAdjustment'
import { displayFormater } from 'v2source/tools/formatter'

import { JAPAN_CURRENCY_ID } from 'utils/constants'

import { removeUnnecessaryProps } from './Forms/utils'

export function Input({
  asText,
  autoBlurOnPressEnter = true,
  trim = true,
  disableAutoSelectAllOnFocus,
  ...props
}: LDXCommon.InputProps) {
  const { t } = useTranslation()
  return (
    <AntInput
      placeholder={
        props.disabled || asText || props.readOnly ? '' : (t('input') as string)
      }
      allowClear
      suffix
      {...removeUnnecessaryProps(props)}
      className={clsx(
        'w-full',
        props.className,
        asText ? 'as-text' : undefined,
      )}
      bordered={asText && !props.disabled ? false : props.bordered}
      readOnly={props.readOnly || asText}
      onPressEnter={(event) => {
        if (autoBlurOnPressEnter) {
          event.preventDefault()
          event.currentTarget?.blur()
        }
        props?.onPressEnter?.(event)
      }}
      onFocus={(e) => {
        props?.onFocus?.(e)
        if (disableAutoSelectAllOnFocus) {
          // Delay the cursor placement to ensure it overrides the default behavior
          setTimeout(() => {
            const input = e.target
            const length = input.value.length
            input.setSelectionRange(length, length)
          }, 300)
        }
      }}
      onBlur={(e) => {
        if (trim && e.target.value?.trim() !== e.target.value) {
          e.target.value = e.target.value?.trim()
          props?.onChange?.(e)
        }
        props?.onBlur?.(e)
      }}
    />
  )
}
function InputNumber(props: LDXCommon.InputNumberProps) {
  const { t } = useTranslation()
  const internalValue = useObservable(props.value ?? props.defaultValue)
  const {
    controls = false,
    decimal = true,
    currencyId = null,
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits,
    roundingMethodUsage,
    defaultNaNValue,
    textAlign,
    roundingMethod,
    useRounding = false,
    asText = false,
    autoBlurOnPressEnter = true,
    showCurrencySymbol,
    forceNegativeValue,
    asTextClassName,
    infinityValueFallback,
    disableAutoSelectAllOnFocus,
    useLocalValue = true,
    showPositiveSign,
    ...restProps
  } = props
  const [focused, setFocused] = useSafeState(false)

  const localValue = internalValue.get()

  useEffect(() => {
    if (props.value && props.value !== localValue) {
      internalValue.set(props.value)
    }
  }, [props.value])

  const { formatter, parse, currencyData, isFetching } =
    useNumberCurrencyAdjustment({
      roundingMethodUsage,
      roundingMethod,
      useRounding,
      decimal,
      decimalPlaces: decimalPlaces,
      minimumFractionDigits: focused ? 0 : decimal ? minimumFractionDigits : 0,
      currencyId,
      displayFormatFallbackValue: decimal
        ? defaultNaNValue ?? 0
        : defaultNaNValue,
    })
  const addOnCurrencySymbol = !!showCurrencySymbol && !!currencyId && decimal

  function getCurrencySymbolAddOn(key: 'after' | 'before') {
    if (!addOnCurrencySymbol) return undefined
    if (key === 'after') {
      return showCurrencySymbol === 'after' ? currencyData?.symbol : undefined
    }
    return currencyData?.symbol
  }

  const getFormatter = useCreation(() => {
    return () => formatter
  }, [omit(props, ['value']), isFetching, currencyData, focused, localValue])

  function getParser() {
    return (val?: string) => {
      if (!val)
        return !isUndefined(defaultNaNValue) ? (defaultNaNValue as any) : 0
      return parse(val)
    }
  }

  const customClassNames = clsx(
    controls && !props.disabled && !props.readOnly
      ? 'number-has-control'
      : undefined,
    `number-align-${textAlign || 'left'}`,
    props.className || 'w-full',
    'common-number-input',
  )

  const getTextValue = useMemoizedFn((value: number) => {
    if (!decimal && !asText) return undefined
    if (!isFinite(value))
      return infinityValueFallback ? String(infinityValueFallback) : '∞'
    return formatter(value)
    // return isNaN(value) ? defaultNaNValue ?? 0 : _val;
  })

  const value = isUndefined(props.value) ? localValue : props.value

  const elementKey = useCreation(() => {
    return `${asText}-${isFetching}-${minimumFractionDigits}`
  }, [focused, asText, isFetching, minimumFractionDigits])

  if (asText || isFetching) {
    const isNegative =
      (forceNegativeValue && !!value) || (value && Number(value) < 0)
        ? true
        : false
    const _classNames = clsx(
      asTextClassName,
      'input-number-display-text',
      `number-align-${textAlign || 'left'}`,
      isNegative ? 'negative-sign' : '',
      showPositiveSign && typeof value === 'number' && value > 0
        ? 'positive-sign'
        : '',
      !!restProps.min || restProps.min === 0 ? 'positive' : '',
      isFetching ? 'fetching' : '',
    )
    return (
      <span className={_classNames}>
        {props.addonBefore || addOnCurrencySymbol ? (
          <span
            className={clsx(
              'addon-before',
              props.addonBefore ? null : 'currency-symbol',
            )}
          >
            {props.addonBefore ?? getCurrencySymbolAddOn('before')}
          </span>
        ) : undefined}
        {typeof value !== 'number' || Number(value) === 0
          ? defaultNaNValue ?? 0
          : getTextValue(Math.abs(value || 0))}
        {props.addonAfter ||
        (addOnCurrencySymbol && showCurrencySymbol === 'after') ? (
          <span
            className={clsx(
              'addon-after',
              props.addonAfter ? null : 'currency-symbol',
            )}
          >
            {props.addonAfter ?? getCurrencySymbolAddOn('after')}
          </span>
        ) : undefined}
      </span>
    )
  }

  return (
    <AntInputNumber
      key={elementKey}
      placeholder={props.disabled ? '' : (t('input_number') as string)}
      formatter={getFormatter()}
      parser={getParser()}
      addonBefore={getCurrencySymbolAddOn('before')}
      addonAfter={getCurrencySymbolAddOn('after')}
      {...removeUnnecessaryProps(restProps)}
      value={useLocalValue ? localValue : value}
      onChange={(...args) => {
        internalValue.set(args[0])
        props.onChange?.(...args)
      }}
      bordered={asText && !props.disabled ? false : props.bordered}
      controls={controls}
      className={customClassNames}
      // bordered={asText ? false : restProps?.bordered}
      // readOnly={asText}
      onPressEnter={(event) => {
        if (autoBlurOnPressEnter) {
          event.preventDefault()
          event.currentTarget?.blur()
        }
        restProps?.onPressEnter?.(event)
      }}
      onFocus={(e) => {
        setFocused(true)
        restProps?.onFocus?.(e)

        if (disableAutoSelectAllOnFocus) {
          // Delay the cursor placement to ensure it overrides the default behavior
          setTimeout(() => {
            const input = e.target
            const length = input.value.length
            input.setSelectionRange(length, length)
          }, 300)
        }
      }}
      onBlur={(e) => {
        if (minimumFractionDigits) {
          setFocused(false)
        }
        restProps?.onBlur?.(e)
      }}
    />
  )
}
InputNumber.ExchangeRate = function InputNumberExchangeRate({
  ...props
}: Parameters<typeof InputNumber>[0]) {
  return (
    <InputNumber
      decimal
      minimumFractionDigits={false}
      maximumFractionDigits={16}
      useRounding
      roundingMethod="rounding"
      {...props}
    />
  )
}
InputNumber.Percentage = function InputNumberRatioInPercentage({
  transformToRatio,
  ...props
}: LDXCommon.InputNumberRatioInPercentageProps) {
  return (
    <InputNumber
      decimal
      maximumFractionDigits={props.currencyId ? undefined : 2}
      minimumFractionDigits={0}
      addonAfter="%"
      useRounding
      roundingMethod="rounding"
      {...props}
      value={
        transformToRatio ? ((props.value as number) || 0) * 100 : props.value
      }
      onChange={(val) => {
        if (transformToRatio) {
          props.onChange?.(val ? (val as number) / 100 : 0)
        } else {
          props.onChange?.(val)
        }
      }}
    />
  )
}
InputNumber.Currency = function InputNumberCurrency(
  props: LDXCommon.InputNumberProps,
) {
  return (
    <InputNumber
      decimal
      useRounding
      minimumFractionDigits
      // showCurrencySymbol
      currencyId={JAPAN_CURRENCY_ID}
      {...props}
    />
  )
}
InputNumber.Quantity = function InputNumberQuantity(
  props: LDXCommon.InputNumberProps,
) {
  const { data } = useNumberCurrencyAdjustment.searchContractMaster()
  const decimalPlaces = data?.qty_decimal_places || 0
  return (
    <InputNumber
      decimal
      useRounding
      maximumFractionDigits={decimalPlaces}
      roundingMethod="rounding"
      minimumFractionDigits={false}
      {...props}
    />
  )
}
InputNumber.MultiplicationRatio = function InputNumberMultiplicationRatio(
  props: LDXCommon.InputNumberProps,
) {
  const { data } = useNumberCurrencyAdjustment.searchContractMaster()
  const decimalPlaces = data?.ratio_decimal_places || 0

  const addonAfter = useCreation(() => {
    if (!isFinite(Number(props?.value)) && props?.asText) {
      return undefined
    }
    return props?.addonAfter ?? '%'
  }, [props?.addonAfter, props?.value, props?.asText])

  return (
    <InputNumber
      min={0}
      max={100}
      decimal
      useRounding
      maximumFractionDigits={decimalPlaces}
      roundingMethod="rounding"
      minimumFractionDigits={false}
      infinityValueFallback={'-'}
      {...props}
      addonAfter={addonAfter}
    />
  )
}
Input.Number = InputNumber
Input.NumberRange = function InputNumberRange({
  value,
  onChange,
  ...props
}: LDXCommon.InputNumberRangeProps) {
  const { t } = useTranslation(['rfm_analysis', 'common'])
  const properProps = omit(props, ['name', 'id'])
  const [min, max] = value ?? []

  return (
    <div
      className="input-number-range group flex items-center gap-2"
      onBlur={() => {
        if (typeof max === 'number' && typeof min === 'number' && min > max) {
          onChange?.([max, max])
        }
      }}
    >
      <Input.Number
        autoBlurOnPressEnter={false}
        value={min}
        placeholder={t('min_value')}
        defaultNaNValue={null}
        {...removeUnnecessaryProps(properProps)}
        className={clsx('w-full', props.className)}
        // max={typeof max === 'number' ? max : undefined}
        onChange={(val) => {
          onChange?.([val as any, max])
        }}
      />
      <SwapRightOutlined className="input-number-separator" />
      <Input.Number
        autoBlurOnPressEnter={false}
        value={max}
        placeholder={t('max_value')}
        defaultNaNValue={null}
        {...removeUnnecessaryProps(properProps)}
        className={clsx('w-full', props.className)}
        // min={typeof min === 'number' ? min : undefined}
        onChange={(val) => {
          onChange?.([min, val as any])
        }}
      />
      {(typeof min === 'number' || typeof max === 'number') && (
        <CloseCircleFilled
          className="clear-icon cursor-pointer"
          onClick={() => {
            onChange?.([])
          }}
        />
      )}
    </div>
  )
}
Input.Password = function InputPassword(props: LDXCommon.InputPasswordProps) {
  const { t } = useTranslation()
  return (
    <AntInput.Password
      placeholder={
        props.disabled || props.readOnly ? '' : (t('input') as string)
      }
      allowClear
      suffix
      {...removeUnnecessaryProps(props)}
      className={clsx('w-full', props.className)}
    />
  )
}
Input.Search = function InputSearch(props: LDXCommon.InputSearchProps) {
  const { t } = useTranslation()
  return (
    <AntInput.Search
      placeholder={
        props.disabled || props.readOnly ? '' : (t('search') as string)
      }
      allowClear
      suffix
      {...removeUnnecessaryProps(props)}
      className={clsx('w-full', props.className)}
    />
  )
}
Input.TextArea = function InputTextArea({
  asText,
  ...props
}: LDXCommon.InputTextAreaProps) {
  const { t } = useTranslation()
  if (asText) {
    return <p>{props.value as any}</p>
  }
  return (
    <AntInput.TextArea
      allowClear
      bordered={asText && !props.disabled ? false : props.bordered}
      placeholder={
        props.disabled || props.readOnly ? '' : (t('input') as string)
      }
      {...removeUnnecessaryProps(props)}
      className={clsx('common-textarea-input w-full', props.className)}
    />
  )
}
Input.Group = AntInput.Group
Input.Native = Input

Input.Checkbox = function InputCheckbox(props: LDXCommon.InputCheckboxProps) {
  return <AntCheckbox {...props} />
}
// Input.Radio = function InputRadio(props: LDXCommon.InputCheckboxProps) {
// 	return <AntCheckbox {...props} />;
// };

Input.TextNumber = function TextNumberInput({
  decimal,
  maximumFractionDigits,
  defaultNaNValue,
  onChange,
  ...props
}: LDXCommon.InputTextNumberProps) {
  function renderValue() {
    if (!props.value) return props.value
    return decimal
      ? displayFormater.number.inputFn.WithOptions({
          maximumFractionDigits: maximumFractionDigits || 2,
        })(props.value)
      : props.value.toString()
  }
  return (
    <Input
      {...props}
      value={renderValue()}
      onChange={
        onChange
          ? (e) => {
              const valInText = e.target.value
              let value = defaultNaNValue || 0
              if (valInText) {
                value = displayFormater.number.parseNumber(valInText)
              }
              onChange?.(value.toString())
            }
          : undefined
      }
    />
  )
}
