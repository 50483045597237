import {
  addedDiff,
  deletedDiff,
  detailedDiff,
  diff,
  updatedDiff,
} from 'deep-object-diff'
import { deepmerge } from 'deepmerge-ts'
import dotObject from 'dot-object'
import { isUndefined, omit, omitBy, pick, pickBy } from 'lodash'

import { DateConfig } from './formatter'

function convertRecordToDotObjectable(d: object) {
  function normalizeValue(k: any): any {
    // check date first because moment is an object
    if (
      DateConfig.isDateInstance(k) &&
      typeof k === 'object' &&
      'toISOString' in k
    ) {
      return k.toISOString()
    } else if (typeof k === 'object') {
      return convertRecordToDotObjectable(k)
    } else if (Array.isArray(k)) {
      return k.map(normalizeValue)
    }
    return k
  }
  return Object.entries(d)
    .map(([key, value]) => {
      return { [key]: value ? normalizeValue(value) : value }
    })
    .reduce((prev, curr) => ({ ...prev, ...curr }), {})
}

function normalizeDotValue(k: any): any {
  if (typeof k === 'string') {
    if (DateConfig.isValid(k)) {
      return DateConfig.date(k)
    }
  }
  return k
}
function normalizeDotObjectValue(values: Record<string, any>) {
  return Object.entries(values).reduce((prev, [key, value]) => {
    return { ...prev, [key]: normalizeDotValue(value) }
  }, {})
}

function compareDotObject(
  original: Record<string, any>,
  newValues: Record<string, any>,
) {
  return Object.entries(newValues).reduce((prev, [key, value]) => {
    if (key === 'id' || key.endsWith('.ormCode') || key.endsWith('.id')) {
      return { ...prev, [key]: value }
    }
    if (original[key] !== value) {
      return { ...prev, [key]: value }
    }
    return prev
  })
}

const objectUtility = {
  deepmerge,
  compare: {
    // use diff to get changed fields
    diff: (
      originalObj: object,
      updatedObj: object,
      {
        excludeArrayType = true,
        excludeId = true,
      }: { excludeArrayType?: boolean; excludeId?: boolean } = {},
    ) => {
      const excludeFields = excludeArrayType
        ? Object.entries(updatedObj).map(([key, value]) => {
            if (Array.isArray(value)) return key
            return undefined
          })
        : []
      return omitBy(
        {
          ...diff(
            convertRecordToDotObjectable(originalObj),
            convertRecordToDotObjectable(updatedObj),
          ),
          ...pick(
            updatedObj,
            [...excludeFields, excludeId ? 'id' : undefined].filter(
              (k) => !!k,
            ) as any,
          ),
        },
        isUndefined,
      )
    },
    addedDiff,
    deletedDiff,
    updatedDiff,
    detailedDiff,
    normalizeDiffArrayResultDataType<Result = any, Input = any>(
      updatedValue: Input,
    ) {
      const updatedLines = {}
      dotObject.dot(updatedValue, updatedLines)
      return dotObject.object(updatedLines) as Result
    },
  },
  clean: (record: Record<string, any>) => {
    return pickBy(record, (v) => !isUndefined(v)) as typeof record
  },
  removeUndefined: (record: Record<string, any>) => {
    return pickBy(record, (v) => !isUndefined(v)) as typeof record
  },
  getChangedValues<T = any>(
    initialValues: Partial<T>,
    newValues: Partial<T>,
    omittedFields: string[] = ['id'],
  ) {
    const _dotInitialValues = dotObject.dot(
      convertRecordToDotObjectable(
        omitBy(omit(initialValues, omittedFields), isUndefined),
      ),
    )
    const _dotNewValues = dotObject.dot(
      convertRecordToDotObjectable(
        omitBy(omit(newValues, omittedFields), isUndefined),
      ),
    )
    const result = compareDotObject(_dotInitialValues, _dotNewValues)
    return normalizeDotObjectValue(dotObject.object(result)) as Partial<T>
  },
}

export { objectUtility }
