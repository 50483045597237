import { get, isUndefined, omit } from 'lodash'
import moment from 'moment'
import { dotObject } from 'v2source/tools'

export const INITIAL_VALUES_FIELD_KEY = 'internalinitialValues'
export const IS_SUBMITTING_FIELD_KEY = 'internalisSubmitting'
export const SUBMIT_COUNT_FIELD_KEY = 'internalsubmitCount'
export const ONSUBMIT_PASS_FROM_USE_FORM_KEY = 'internalonSubmitfromUseForm'

export const defaultFormInternalValues = {
  [IS_SUBMITTING_FIELD_KEY]: false,
  [SUBMIT_COUNT_FIELD_KEY]: 0,
}
const __formInternalFields = [
  INITIAL_VALUES_FIELD_KEY,
  IS_SUBMITTING_FIELD_KEY,
  SUBMIT_COUNT_FIELD_KEY,
  ONSUBMIT_PASS_FROM_USE_FORM_KEY,
]
export function sanitizeFormFromIntervalValues<T extends object = any>(
  values: T,
) {
  const omittedFields = [...__formInternalFields]
  if (isUndefined((values as any)?.id)) {
    omittedFields.push('id')
  }
  return omit(values, omittedFields) as T
}

/**
 *
 * @param name to transform field name to Form supported format
 * @returns
 */

export function normalizeFormFieldName(name: LDXForm.NamePath) {
  if (typeof name === 'string' && name.includes('.')) {
    return name.split('.').map((s) => {
      if (s.startsWith('[') && s.endsWith(']')) return Number(s.match(/\d+/)[0])
      return isNaN(Number(s)) ? s : Number(s)
    })
  }
  return name
}
export function convertFieldsValueToRecursivePartial<T = any>(data: any) {
  return dotObject.object(data) as T
}

export const commonFormValidationRule = {
  stringRequired: {
    required: true,
    whitespace: true,
  } as LDXForm.ValidationRule,
}

export function customFormValidationRule(rule: LDXForm.ValidationRule) {
  return {
    textNumberInput: {
      async validator(c, value?: string) {
        /* istanbul ignore next: is just for safety, it will not execute because default value is 0 */
        if (c.required && !value?.trim().length)
          throw new Error('error_required')
        if (isNaN(Number(value)) || !value) return
        if ('min' in rule && Number(value) < c.min!) {
          throw new Error('error_required')
        }
      },
      ...rule,
    } as LDXForm.ValidationRule,
  }
}

/**
 * @description this function will sanitize translateableForm values to only return valueByLang that has been changed
 * @param values
 * @returns
 */
export function translateableFormSanitize(
  values: LDXForm.FormContextState['translateableForm'],
) {
  if (!values) return values
  const _dots = dotObject.dot({ ...(values || {}) })
  const sanitizedValues = Object.entries(_dots)
    // filter only valueByLang that has been changed
    .filter(([key, value]: [string, any]) => {
      // skip others field, because we just need valueByLang to check
      if (!key.includes('.valueByLang.')) return true
      if ([null, undefined].includes(value)) return false
      const fieldName = key.split('.valueByLang.')[0]
      const modelId = get(values, `${fieldName}.modelId`) as unknown as
        | number
        | undefined
      const currentLang = get(values, `${fieldName}.currentLang`) as unknown as
        | string
        | undefined
      const lang = key.split('.valueByLang.')[1]
      // skip if currentLang is same with lang
      if (lang === currentLang) return false
      const initialValue = get(
        values,
        key.replace('valueByLang', 'initialValueByLang'),
      )
      // if has modelId which means its already exists on database, then initialValue must be exists if modal is open which means request for initial value is success
      if (!!modelId && !initialValue) return false
      return initialValue ? initialValue !== value : true
    })
    .reduce((prev, [key, value]) => {
      return {
        ...prev,
        [key]: value,
      }
    }, {})
  return Object.entries(
    dotObject.object(sanitizedValues)! as NonNullable<
      LDXForm.FormContextState['translateableForm']
    >,
  )
    .filter(([_, val]) => {
      return !!val.valueByLang
    })
    .reduce((prev, [key, value]) => {
      return {
        ...prev,
        [key]: value,
      }
    }, {}) as NonNullable<LDXForm.FormContextState['translateableForm']>
}
export function removeUnnecessaryProps(props: any) {
  return omit(props, [
    'decimal',
    'maximumFractionDigits',
    'minimumFractionDigits',
    'roundingMethodUsage',
    'defaultNaNValue',
    'textAlign',
    'roundingMethod',
    'useRounding',
    'asText',
    'autoBlurOnPressEnter',
    'showCurrencySymbol',
    'forceNegativeValue',
    'asTextClassName',
    'infinityValueFallback',
    'transformToRatio',
    'currencyId',
    'includeCurrentFieldValueToUpdateInCustomOnChange',
    'validateUponChange',
  ])
}

export function assignInternalId(data) {
  // Helper function to generate a unique ID
  const generateUniqueId = (index) => {
    return `${Date.now()}_${index}`
  }

  // Recursive function to traverse the data structure
  const traverse = (item, parentKey = '', rootData = data) => {
    if (Array.isArray(item)) {
      // Generate key extractor field name
      const keyExtractorName = `${parentKey}_keyExtractor`
      if (
        typeof item[0] === 'object' &&
        item[0] !== null &&
        !moment.isMoment(item[0])
      ) {
        // Ensure key extractor field is added to rootData if not present
        if (typeof rootData[keyExtractorName] !== 'string') {
          rootData[keyExtractorName] = 'internal__id'
        }
      }

      // Handle arrays
      return item.map((subItem, index) => {
        // If the item is an object, ensure it has an internal__id
        if (typeof subItem === 'object' && subItem !== null) {
          const idField = rootData[keyExtractorName]
          // eslint-disable-next-line no-prototype-builtins
          if (!subItem.hasOwnProperty(idField)) {
            subItem[idField] = generateUniqueId(index)
          }
          // Recursively check the object's properties
          return traverse(subItem, `${parentKey}[${index}]`, rootData)
        }
        // Return primitive types as is
        return subItem
      })
    } else if (moment.isMoment(item)) {
      // Handle moment objects
      return item
    } else if (typeof item === 'object' && item !== null) {
      // Handle objects
      const result = {}
      for (const key in item) {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty(key)) {
          result[key] = traverse(item[key], key, rootData)
        }
      }
      return result
    } else {
      // Return primitive types as is
      return item
    }
  }

  // Start traversing the data structure
  traverse(data)
  return data
}
