import type { QueryFunction, QueryKey } from 'react-query'
import { useMutation, useQuery } from 'react-query'

import type { AxiosError } from 'axios'
import { find as findItem, isEqual, matches } from 'lodash'

import { queryClient } from 'components/Provider'

function getQueryFilterByQueryKey(queryKey: string | any[]) {
  return {
    predicate: (query: any) => {
      // console.log({ query, queryKey });
      if (Array.isArray(queryKey)) {
        return isEqual(queryKey, query.queryKey)
      }
      const m = matches(query?.queryKey)
      return query?.queryKey?.[0] === queryKey || m(queryKey)
    },
  }
}

export const queryClientHelpers = (queryKey: any) => {
  // !IMPORTANT do not use queryClient.isFetching | isMutating, because is stateless
  // ! use useIsFetching | useIsMutating provide by tanstack query library
  const config = {
    remove: () => queryClient.removeQueries({ queryKey }),
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    refetchQueries: () => queryClient.refetchQueries({ queryKey }),
    clear: queryClient.clear,
    cancel: () => queryClient.cancelQueries({ queryKey }),
    reset: () => queryClient.resetQueries({ queryKey }),
  }
  return {
    ...config,
    client: queryClient,
    invalidate: config.remove,
  }
}
queryClientHelpers.getQueryFilterByQueryKey = getQueryFilterByQueryKey
queryClientHelpers.getQueryFilterByGroupKey = function getQueryFilterByGroupKey(
  key?: string,
) {
  const filters = {
    predicate: (query: any) => {
      // console.log({ query: query.queryKey, queryGroup });
      if (Array.isArray(query?.queryKey)) {
        return !!findItem(
          query?.queryKey,
          // if key provide look for queryGroup, else use pageHref
          key
            ? (v) => {
                if (typeof v === 'object') {
                  const [queryKey, value] = Object.entries(v)[0]
                  return queryKey === 'queryGroup' && Array.isArray(value)
                    ? value.includes(key)
                    : value === key
                }
                return false
              }
            : { queryGroupByPageHref: window.location.pathname },
        )
      }
      return false
    },
  }
  return {
    remove: () => queryClient.removeQueries(filters),
    invalidate: () => queryClient.invalidateQueries(filters),
    refetchQueries: () => queryClient.refetchQueries(filters),
    clear: queryClient.clear,
    cancel: () => queryClient.cancelQueries(filters),
    reset: () => queryClient.resetQueries(filters),
    filters,
    client: queryClient,
    invalidateQuery: () => {
      // queryClient.removeQueries(filters);
      queryClient.invalidateQueries(filters)
    },
  }
}

export function useQueryBuilder<
  Response extends ApiService.DefaultResponse,
  TQueryFnData = Response,
  ErrorResponse = ApiService.ResponseError,
>({
  queryOptions,
  queryKey,
  queryFn,
}: {
  queryOptions?: ApiService.GeneralUseQueryOptions<
    TQueryFnData,
    ErrorResponse
  > & {
    groupKey?: string | string[]
    queryKey?: QueryKey
    onSuccess?: (data: TQueryFnData) => void
    onError?: (error: AxiosError<ErrorResponse>) => void
  }
  queryFn?: QueryFunction<Response>
  queryKey?: QueryKey
}) {
  const _key = queryKey || queryOptions?.queryKey
  const key = Array.isArray(_key) ? _key : [_key]
  const _queryKey = [
    ...key,
    {
      queryGroup: Array.isArray(queryOptions?.groupKey)
        ? queryOptions?.groupKey
        : [queryOptions?.groupKey || window.location.pathname],
    },
    { queryGroupByPageHref: window.location.pathname },
  ]

  const qu = useQuery<Response, AxiosError<ErrorResponse>>({
    queryFn,
    ...(queryOptions as any),
    queryKey: _queryKey,
  })
  /* WORK AROUND FOR QUERY v.5 */
  // useUpdateEffect(() => {
  //   if (qu.isSuccess) {
  //     queryOptions?.onSuccess?.(qu.data as any)
  //   }
  // }, [qu.isSuccess])
  // useUpdateEffect(() => {
  //   if (qu.isError) {
  //     queryOptions?.onError?.(qu.error)
  //   }
  // }, [qu.isError])
  return {
    ...qu,
    remove: async () => {
      queryClient.removeQueries({ queryKey: _queryKey })
    },
  }
}

export function useDataMutation<
  ResponseData = any,
  QueryFnArgs = any,
  ErrorResponse = ApiService.ResponseError,
>(
  props: ApiService.UseDataMutationProps<
    ResponseData,
    QueryFnArgs,
    ErrorResponse
  >,
) {
  return useMutation(props)
}
