import { Icon } from '@iconify/react'
import { Observable } from '@legendapp/state'
import { useCreation, useDebounce } from 'ahooks'
import type { CollapsePanelProps, CollapseProps } from 'antd'
import { Collapse as AntCollapse, Modal as AntModal } from 'antd'
import { clsx } from 'clsx'
export {
  AppstoreOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
  LinkOutlined,
  LoginOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'

// import ListBoxesIcon from 'virtual:vite-icons/carbon:list-boxes';
// import ShipmentUploadIcon from 'virtual:vite-icons/streamline:shipment-upload';
// export { ListBoxesIcon, ShipmentUploadIcon };

export function Modal(props: Parameters<typeof AntModal>[0]) {
  return (
    <AntModal
      destroyOnClose
      {...props}
      className={clsx('default-modal', props.className)}
    />
  )
}
Modal.confirm = AntModal.confirm
Modal.info = AntModal.info
Modal.success = AntModal.success
Modal.warning = AntModal.warning
Modal.error = AntModal.error
Modal.destroyAll = AntModal.destroyAll
Modal.useModal = AntModal.useModal

export function Collapse(props: CollapseProps) {
  return (
    <AntCollapse
      bordered={false}
      expandIconPosition={'end'}
      {...props}
      className={clsx('default-collapse bg-transparent', props.className)}
    />
  )
}
Collapse.Panel = function CollapsePanel(props: CollapsePanelProps) {
  return (
    <AntCollapse.Panel
      {...props}
      className={clsx(
        'default-collapse-panel border-0 bg-transparent',
        props.className,
      )}
    />
  )
}

export function ObservableStateWrapper<T = any>(props: {
  observable: Observable<T>
  children: (value: T) => JSX.Element
  /**
   * @default 700ms
   */
  debounceWait?: number
}) {
  const value = useDebounce(props.observable.get(), {
    wait: 700,
    leading: true,
  })
  return props.children(value)
}
ObservableStateWrapper.Boolean = function ObservableBoolean(props: {
  observable: Observable<boolean>
  children: (value: boolean) => JSX.Element
}) {
  const value = props.observable.get()
  return props.children(value)
}
ObservableStateWrapper.Number = function ObservableNumber(props: {
  observable: Observable<number>
  children: (value: number) => JSX.Element
}) {
  const value = props.observable.get()
  return props.children(value)
}

export function MemoizedRendering(props: { data: any; children: JSX.Element }) {
  return useCreation(() => props.children, [JSON.stringify(props.data)])
}

export function Iconify(props: Parameters<typeof Icon>[0]) {
  return <Icon {...props} className={clsx('ldx-icons', props.className)} />
}
