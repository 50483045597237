import { max } from 'lodash'
import { displayFormater } from 'v2source/tools/formatter'

export function getNumberCurrencyFormatter(
  config?: Pick<
    LDXCommon.NumberCurrencyFormaterProps,
    | 'decimalPlaces'
    | 'roundingMethod'
    | 'displayFormatFallbackValue'
    | 'minimumFractionDigits'
  >,
) {
  const {
    decimalPlaces: decimal_places = 0,
    roundingMethod: rounding_method,
    minimumFractionDigits = 0,
    displayFormatFallbackValue,
  } = config || {}
  const formatter = displayFormater.number.inputFn.WithOptions({
    maximumFractionDigits: max([decimal_places, minimumFractionDigits]),
    minimumFractionDigits,
    roundingMethod: rounding_method,
    fallbackValue: displayFormatFallbackValue as any,
  })
  function parse(value: any) {
    return displayFormater.number.parseNumber(value, {
      maximumFractionDigits: decimal_places,
      roundingMethod: rounding_method,
    })
  }
  /**
   *
   * @param value number | string
   * @method rounding_method and decimal_places will be used
   * @returns rounded value
   */
  function adjust(value: any) {
    return parse(formatter(value))
  }
  adjust.config = config
  return {
    formatter,
    parse,
    adjust,
  }
}
