import { useQuery, UseQueryOptions } from 'react-query'

import { BASE_API_URL } from 'constant'
import ApiCall from 'services/ApiCall'

import useUrlQuery, { UseUrlQueryOptions } from 'helpers/QueryUrl/useUrlQuery'

export interface ContractAttributes {
  id: number
  name: string
  sequence: number
  parent_id?: string
  child_ids: any[]
  partner_id: Array<number | string>
  report_header?: string
  report_footer?: string
  logo: string
  logo_web: string
  currency_id: Array<number | string>
  user_ids: number[]
  street: string
  street2?: string
  zip: string
  city: string
  state_id?: string
  bank_ids: any[]
  country_id?: string
  email: string
  phone: string
  website: string
  vat?: string
  company_registry?: string
  paperformat_id: Array<number | string>
  external_report_layout_id?: string
  base_onboarding_company_state: string
  favicon: string
  font: string
  primary_color?: string
  secondary_color?: string
  nomenclature_id: Array<number | string>
  resource_calendar_ids: number[]
  resource_calendar_id: Array<number | string>
  catchall_email: string
  catchall_formatted: string
  email_formatted: string
  partner_gid: number
  snailmail_color?: string
  snailmail_cover?: string
  snailmail_duplex?: string
  fiscalyear_last_day: number
  fiscalyear_last_month: string
  period_lock_date?: string
  fiscalyear_lock_date?: string
  tax_lock_date?: string
  transfer_account_id?: string
  expects_chart_of_accounts?: string
  chart_template_id?: string
  bank_account_code_prefix?: string
  cash_account_code_prefix?: string
  default_cash_difference_income_account_id?: string
  default_cash_difference_expense_account_id?: string
  account_journal_suspense_account_id?: string
  transfer_account_code_prefix?: string
  account_sale_tax_id?: string
  rounding_method: string
  account_purchase_tax_id?: string
  tax_calculation_rounding_method: string
  currency_exchange_journal_id: Array<number | string>
  income_currency_exchange_account_id: Array<number | string>
  expense_currency_exchange_account_id: Array<number | string>
  anglo_saxon_accounting?: string
  property_stock_account_input_categ_id?: string
  property_stock_account_output_categ_id?: string
  property_stock_valuation_account_id?: string
  bank_journal_ids: number[]
  tax_exigibility?: string
  account_tax_fiscal_country_id?: string
  incoterm_id?: string
  qr_code?: string
  invoice_is_email?: string
  invoice_is_print?: string
  account_opening_move_id?: string
  account_opening_journal_id?: string
  account_opening_date: string
  account_setup_bank_data_state: string
  account_setup_fy_data_state: string
  account_setup_coa_state: string
  account_onboarding_invoice_layout_state: string
  account_onboarding_create_invoice_state: string
  account_onboarding_sale_tax_state: string
  account_invoice_onboarding_state: string
  account_dashboard_onboarding_state: string
  invoice_terms?: string
  account_setup_bill_state: string
  account_default_pos_receivable_account_id?: string
  expense_accrual_account_id?: string
  revenue_accrual_account_id?: string
  automatic_entry_default_journal_id?: string
  country_code?: string
  tax_cash_basis_journal_id?: string
  account_cash_basis_base_account_id?: string
  internal_transit_location_id: Array<number | string>
  stock_move_email_validation?: string
  stock_mail_confirmation_template_id: Array<number | string>
  manufacturing_lead: number
  payment_acquirer_onboarding_state: string
  payment_onboarding_payment_method?: string
  invoice_is_snailmail?: string
  stock_move_sms_validation?: string
  stock_sms_confirmation_template_id: Array<number | string>
  has_received_warning_stock_sms?: string
  portal_confirmation_sign?: string
  portal_confirmation_pay?: string
  quotation_validity_days: number
  sale_quotation_onboarding_state: string
  sale_onboarding_order_confirmation_state: string
  sale_onboarding_sample_quotation_state: string
  sale_onboarding_payment_method?: string
  sale_order_template_id?: string
  security_lead: number
  number_of_users: number
  material_control_threshold: number
  modules_plan?: string
  modules_production?: string
  modules_inventory?: string
  modules_mdplan?: string
  modules_inseason?: string
  modules_budget?: string
  proper_judgment_method: string
  proper_judgment_value: number
  input_format_for_code?: string
  input_format_for_code_error_message?: string
  pdf_company_name?: string
  pdf_address?: string
  pdf_building_name?: string
  pdf_country_id?: string
  pdf_prefectures_id?: string
  pdf_post_code?: string
  pdf_phone?: string
  pdf_bank_information?: string
  order_sheet_statement?: string
  company_cost_method: string
  allow_negative_inventory?: string
  warehouse_ids: number[]
  warehouse_inventory_negative_ids: any[]
  multiplication_rate_client_setting: 'disable' | 'enable'
  rounding_method_sales: 'rounding' | 'round_up' | 'round_down'
  rounding_method_purchase: 'rounding' | 'round_up' | 'round_down'
  qty_decimal_places: number
  ratio_decimal_places: number
  currency_decimal_places: number
  tax_free_min_amount: number
  past_data_cutoff_datetime: string
  tax_free_max_amount: number
  display_name: string
  create_uid?: string
  create_date: string
  write_uid: Array<number | string>
  write_date: string
  __last_update: string
  inventory_allocation_type: 'prorate_same_ratio' | 'by_customer_order'

  invoice_statement_1?: string
  invoice_statement_2?: string
  payment_statement_1?: string
  payment_statement_2?: string

  max_replenishment_product?: number
  max_replenishment_store?: number

  order_sheet_type?: 'type_1' | 'type_2'
}

type ContractResult = {
  result: {
    records: ContractAttributes[]
    length: number
  }
}

const useContract = (
  _urlOptions?: UseUrlQueryOptions & {
    lang?: string
    fields?: Extract<keyof ContractAttributes, string>[]
  },
  options?: UseQueryOptions<ContractResult>,
) => {
  const {
    fields = [
      'name',
      'modules_plan',
      'modules_production',
      'modules_inventory',
      'modules_mdplan',
      'modules_inseason',
      'modules_budget',
      'number_of_users',
      'allow_negative_inventory',
      'proper_judgment_method',
      'proper_judgment_value',
      'input_format_for_code',
      'input_format_for_code_error_message',
      'pdf_company_name',
      'pdf_post_code',
      'pdf_country_id',
      'pdf_prefectures_id',
      'pdf_address',
      'pdf_building_name',
      'pdf_phone',
      'pdf_bank_information',
      'order_sheet_statement',
      'warehouse_ids',
      'email',
      'material_control_threshold',
      'logo',
      'company_registry',
      'company_cost_method',
      'rounding_method',
      'rounding_method_purchase',
      'rounding_method_sales',
      'multiplication_rate_client_setting',
      'tax_free_min_amount',
      'tax_free_max_amount',
      'invoice_statement_1',
      'invoice_statement_2',
      'payment_statement_1',
      'payment_statement_2',
      'currency_id',
      'max_replenishment_product',
      'max_replenishment_store',
      'past_data_cutoff_datetime',
      'order_sheet_type',
    ],
    ...urlOptions
  } = _urlOptions || {}
  const urlQuery = useUrlQuery(urlOptions)
  const queryKey = [`core.contract`, fields, urlOptions?.lang]

  const query = useQuery<ContractResult>(
    queryKey,
    () =>
      ApiCall.api
        .post(
          urlQuery.transformUrl(
            `${BASE_API_URL}/dataset/res.company/search_read`,
          ),
          {
            params: {
              fields,
              limit: 1,
              offset: 0,
              domain: null,
              sort: null,
              ...(urlOptions?.lang
                ? { context: { lang: urlOptions?.lang } }
                : {}),
            },
          },
        )
        .then((res) => {
          return res.data
        }),
    options,
  )
  return {
    ...query,
    data: query.data?.result?.records?.[0],
  }
}

export default useContract
