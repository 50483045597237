import { get, sum } from 'lodash'
import { utils } from 'v2source/tools'
import type { AvailableDisplayDataFormaterKeys } from 'v2source/tools/formatter'

import { DateConfig } from '../formatter'

type ExcelColumn = {
  title: string
  dataIndex?: string
  format?: AvailableDisplayDataFormaterKeys
  children?: ExcelColumn[]
}

// s => Start, e => End, r => Row, c => Column
export type ColMergeFormula = {
  s: { r: number; c: number }
  e: { r: number; c: number }
}

function getArrayDepth(
  columns: Partial<ExcelColumn>[],
  options?: { mapKey?: string },
) {
  const mapKey = options?.mapKey || 'title'
  const normalized = (x: Partial<ExcelColumn>): any | any[] => {
    if (x.children) return x.children.map(normalized)
    return get(x, mapKey)
  }
  return utils.getArrayDepth(columns.map(normalized))
}

type FormatDownloadExcelFileNameParams = {
  baseName: string
  baseNameSuffix?: string
}

/**
 * @param params
 * Base name of excel file before formatted ex: Product Master (Should EN/JP)
 *
 * @return
 * Standard excel download name format baseName_YYYYMMDD_hhmm.xlsx
 */
export const formatDownloadExcelFileName = (
  params: string | FormatDownloadExcelFileNameParams,
  withoutExtension?: boolean,
): string => {
  if (typeof params === 'string') {
    if (params.endsWith('.xlsx') || params.endsWith('.xls')) return params
    return `${params}_${DateConfig.date().format('YYYYMMDD_hhmm')}${
      withoutExtension ? '' : '.xlsx'
    }`
  }

  const { baseName, baseNameSuffix = '' } = params

  return `${baseName}${baseNameSuffix}_${DateConfig.date().format(
    'YYYYMMDD_hhmm',
  )}${withoutExtension ? '' : '.xlsx'}`
}

function getColSpan(data: any[], nestedField = 'children'): number {
  return sum(
    data.map((k) =>
      get(k, nestedField) ? getColSpan(get(k, nestedField)) : 1,
    ),
  )
}

export const exportExcelUtility = {
  getArrayDepth,
  formatDownloadExcelFileName,
  getColSpan,
}
