import type { HTMLAttributes } from 'react'

import { clsx } from 'clsx'

export function CodeNameColumnDisplay({
  code,
  name,
  containerClassName = '',
  inline,
}: {
  code?: string
  name: string
  containerClassName?: HTMLAttributes<HTMLDivElement>['className']
  inline?: boolean
}) {
  if (
    !code ||
    !name ||
    !String(code)?.trim()?.length ||
    !String(name)?.trim()?.length
  ) {
    const combined = [String(code)?.trim(), String(name)?.trim()]
      .filter((x) => !!x)
      .join(' ')
    return <span>{combined}</span>
  }
  return (
    <div
      className={clsx(
        'flex',
        inline ? 'items-center' : 'flex-col',
        containerClassName,
      )}
    >
      {!!code && (
        <span className="text-[11px] font-semibold text-gray-500">
          [{code}]
        </span>
      )}
      <span>{name}</span>
    </div>
  )
}
