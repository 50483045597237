import React from 'react'

import { Col, Form, Row } from 'antd'
import { isNumber } from 'lodash'

import { Input } from 'components/Templates/Input'
import { parseNumber } from 'utils'

type Props = {
  startName: string
  endName: string
  suffix?: string
  allowClear?: boolean
}
/**
 * Use this only with Antd Form
 * @returns
 */
const InputRange: React.FC<Props> = ({
  startName,
  endName,
  suffix,
  allowClear = true,
}): JSX.Element => {
  return (
    <Row gutter={0}>
      <Col span={10} className="flex items-center">
        <Form.Item noStyle shouldUpdate>
          {({ setFieldValue, getFieldValue }) => (
            <Form.Item noStyle name={startName}>
              <Input
                type="number"
                style={{
                  width: '100%',
                  borderRightWidth: 0,
                }}
                placeholder="Minimum"
                onChange={(e) => {
                  const newValue = e.target.value
                    ? parseNumber(e.target.value)
                    : e.target.value
                  setFieldValue(startName, newValue)
                }}
                onBlur={() => {
                  const startValue =
                    parseNumber(getFieldValue(startName)) ||
                    getFieldValue(startName)
                  const endValue =
                    parseNumber(getFieldValue(endName)) ||
                    getFieldValue(endName)
                  if (isNumber(endValue) && endValue < startValue) {
                    setFieldValue(endName, startValue)
                  }
                }}
                allowClear={allowClear}
              />
            </Form.Item>
          )}
        </Form.Item>
        {<span>{suffix}</span>}
      </Col>
      <Col span={4}>
        <Input
          className="site-input-split"
          style={{
            width: '100%',
            borderLeft: 0,
            borderRight: 0,
            pointerEvents: 'none',
            backgroundColor: '#fff',
          }}
          placeholder="~"
          disabled
        />
      </Col>
      <Col span={10} className="flex items-center">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => (
            <Form.Item noStyle name={endName}>
              <Input
                type="number"
                className="site-input-right"
                style={{
                  width: '100%',
                  borderLeftWidth: 0,
                }}
                placeholder="Maximum"
                onBlur={() => {
                  const startValue =
                    parseNumber(getFieldValue(startName)) ||
                    getFieldValue(startName)
                  const endValue =
                    parseNumber(getFieldValue(endName)) ||
                    getFieldValue(endName)
                  if (isNumber(endValue) && endValue < startValue) {
                    setFieldValue(endName, startValue)
                  }
                }}
                onChange={(e) => {
                  const newValue = e.target.value
                    ? parseNumber(e.target.value)
                    : e.target.value
                  setFieldValue(endName, newValue)
                }}
                allowClear={allowClear}
              />
            </Form.Item>
          )}
        </Form.Item>
        {<span>{suffix}</span>}
      </Col>
    </Row>
  )
}

export default InputRange
