import i18next from 'i18next'

/**
 * Check file size before upload
 *
 * @param file File object
 * @param fileSize Max file size (default: 10MB)
 * @returns boolean
 */
export const isExceedFileLimit = (file: File, fileSize = 10) => {
  const allow = file.size / 1024 / 1024 < fileSize
  return allow
    ? false
    : i18next
        .t('error_file_size_limit', { size: fileSize })
        ?.replace('10MB', `${fileSize}MB`)
}
/* istanbul ignore next */
export async function readFileAsBinary(file: File) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(this.result)
    }
    /* c8 ignore start */
    reader.onerror = reject
    /* c8 ignore stop */
    reader.readAsBinaryString(file)
  })
}

export function getExtensionFromFileName(filename: string) {
  const s = filename.split('.')
  return s.length > 1 ? s[s.length - 1] : undefined
}
export function getFileBlobPropertyTypeFromFilename(
  filename: string,
  options?: { utf8?: boolean },
) {
  const ext = getExtensionFromFileName(filename)
  const cfg = [options?.utf8 ? 'charset=utf-8' : null].filter((x) => !!x)
  const properties = cfg.length ? `;${cfg.join(',')}` : ''
  if (ext === 'pdf') {
    return `application/pdf` + properties
  }
  if (['xlsx', 'xls'].includes(ext as string)) {
    return `application/vnd.ms-excel` + properties
  }
}
