import { useTranslation } from 'react-i18next'

import { useCreation } from 'ahooks'
import { DatePicker as AntDatePicker } from 'antd'
import { clsx } from 'clsx'

import { DateConfig } from 'components/Templates/Input/services/formatter'
import {
  FORMAT_DATE,
  FORMAT_DATETIME,
  FORMAT_DATETIME_WITHOUT_SECOND,
} from 'utils/constants'
import useAccountClosingStore from 'views/InventoryControl/ClosingProcessSetting/stores/accountClosingStore'

export function DatePicker({
  textAlign,
  skipClosingDate,
  asText,
  autoAdjustTimeShift = true,
  isDateTime,
  showSecond,
  ..._props
}: LDXCommon.DatePickerProps) {
  const { t } = useTranslation(['common'])
  const closedDate = useAccountClosingStore((state) => state.closedDate)
  const format = useCreation(() => {
    if (_props?.format) return _props.format
    if (isDateTime) {
      return showSecond ? FORMAT_DATETIME : FORMAT_DATETIME_WITHOUT_SECOND
    }
    return FORMAT_DATE
  }, [isDateTime, _props.format, showSecond])
  const props = { showTime: isDateTime, ...(_props || {}) }
  return (
    <AntDatePicker
      getPopupContainer={(node) => node.parentElement}
      placeholder={props.disabled || asText ? '' : t('select_date')}
      {...(asText ? { suffixIcon: null } : {})}
      {...(props as any)}
      bordered={asText && !props.disabled ? false : props.bordered}
      format={format}
      onChange={(_value, valueString) => {
        const value = _value ? _value.clone() : null
        if (!isDateTime && autoAdjustTimeShift && value) {
          value.endOf('day')
          valueString =
            typeof format === 'string' ? value.format(format) : valueString
        }
        props?.onChange?.(value as any, valueString)
      }}
      className={clsx(
        'datepicker w-full',
        textAlign ? `date-text-align-${textAlign}` : '',
        asText ? 'datepicker-as-text' : '',
        _props.inputReadOnly ? 'datepicker-input-readonly' : '',
        _props.className,
      )}
      disabledDate={
        closedDate && !skipClosingDate
          ? (...args) => {
              // convert to date only without time
              if (args[0].startOf('day').isSameOrBefore(closedDate as any))
                return true
              return props?.disabledDate?.(...args) || false
            }
          : props?.disabledDate
      }
      disabled={asText || props.disabled || props.inputReadOnly}
    />
  )
}

DatePicker.YearOnly = function DatePickerYearOnly({
  asText,
  ...props
}: LDXCommon.DatePickerYearOnlyProps) {
  const { t } = useTranslation(['common'])
  const currentValue = props.value
    ? DateConfig.toLocalDate(new Date(props.value as number, 1, 1))
    : undefined
  return (
    <AntDatePicker
      getPopupContainer={(node) => node.parentElement}
      placeholder={t('common:year')}
      className="w-full"
      {...props}
      bordered={asText && !props.disabled ? false : props.bordered}
      picker="year"
      format="YYYY"
      value={(currentValue as any) || undefined}
      onChange={(c) => {
        props?.onChange?.(Number(c?.format('YYYY')) || null)
      }}
    />
  )
}
function useCommonDatePresets() {
  const { t } = useTranslation(['arrival_slip_info_list', 'common'])
  return {
    t,
    presets: [
      {
        label: t('arrival_slip_info_list:today'),
        value: [DateConfig.date(), DateConfig.date()],
      },
      {
        label: t('arrival_slip_info_list:next_one_week'),
        value: [DateConfig.date(), DateConfig.date().add(7, 'day')],
      },
      {
        label: t('arrival_slip_info_list:this_week'),
        value: [
          DateConfig.date().startOf('week'),
          DateConfig.date().endOf('week'),
        ],
      },
      {
        label: t('arrival_slip_info_list:this_month'),
        value: [
          DateConfig.date().startOf('month'),
          DateConfig.date().endOf('month'),
        ],
      },
    ],
  }
}
function DateRangePicker({
  useCommonDatePresets: useDateRangeCommon,
  skipClosingDate,
  autoAdjustTimeShift = true,
  isDateTime,
  showSecond,
  ..._props
}: LDXCommon.DateRangePickerProps & { useCommonDatePresets?: boolean }) {
  const { t } = useTranslation(['common'])
  const closingDate = useAccountClosingStore((state) => state.closedDate)
  const { presets: commonDateRanges } = useCommonDatePresets()
  const format = useCreation(() => {
    if (_props?.format) return _props.format
    if (isDateTime) {
      return showSecond ? FORMAT_DATETIME : FORMAT_DATETIME_WITHOUT_SECOND
    }
    return FORMAT_DATE
  }, [isDateTime, _props.format, showSecond])
  const props = { showTime: isDateTime, ...(_props || {}) }
  return (
    <AntDatePicker.RangePicker
      getPopupContainer={(node) => node.parentElement}
      placeholder={[t('startDate'), t('endDate')]}
      className="w-full"
      {...props}
      format={format}
      onChange={(_value, valueString) => {
        if (!isDateTime && autoAdjustTimeShift && _value?.length) {
          // value[0] = value[0]?.endOf('day');
          const _vnew = [
            _value[0] ? _value[0].clone().startOf('day') : _value[0],
            _value[1] ? _value[1].clone().endOf('day') : _value[1],
          ] as typeof _value
          props?.onChange?.(
            _vnew,
            typeof format === 'string'
              ? (_vnew.map((x) =>
                  x ? x.format(format) : '',
                ) as typeof valueString)
              : valueString,
          )
        } else {
          props?.onChange?.(_value, valueString)
        }
      }}
      ranges={
        useDateRangeCommon && !props.ranges
          ? commonDateRanges.reduce(
              (prev, curr) => ({ ...prev, [curr.label]: curr.value }),
              {},
            )
          : props.ranges
      }
      disabledDate={
        closingDate && !skipClosingDate
          ? (...args) => {
              // convert to date only without time
              if (args[0].startOf('day').isAfter(closingDate as any))
                return true
              return props?.disabledDate?.(...args) || false
            }
          : props?.disabledDate
      }
    />
  )
}
DateRangePicker.useCommonDatePresets = useCommonDatePresets
DateRangePicker.YearOnly = function DateRangePicker(
  props: LDXCommon.DateRangePickerYearOnlyProps,
) {
  const { t } = useTranslation(['common'])
  const currentValue: number[] = props.value || []
  return (
    <AntDatePicker.RangePicker
      getPopupContainer={(node) => node.parentElement}
      picker="year"
      format="YYYY"
      placeholder={[t('common:year'), t('common:year')]}
      {...props}
      value={
        currentValue.length
          ? ([
              DateConfig.toLocalDate(new Date(currentValue[0], 1, 1)),
              DateConfig.toLocalDate(new Date(currentValue[1], 2, 1)),
            ] as any)
          : null
      }
      onChange={(c) => {
        if (!c?.[0]) {
          return props?.onChange?.(null)
        }
        return props?.onChange?.([
          Number(c[0]?.format('YYYY')),
          Number(c[1]?.format('YYYY')),
        ])
      }}
      className={`w-full text-center ${props.className || ''}`}
    />
  )
}
DatePicker.Range = DateRangePicker
