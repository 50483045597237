import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { useCreation, useSafeState } from 'ahooks'
import { Button as AntButton, notification, Popover } from 'antd'
import { clsx } from 'clsx'
import { get, omit } from 'lodash'
import ApiCall from 'services/ApiCall'
import { exportJSONToExcel } from 'v2source/tools/excel'
import { isExceedFileLimit } from 'v2source/tools/fileUploadUtil'

import { Iconify, Modal } from './Miscellanous'

type FileGetterMode = 'single' | 'multiple'
type ButtonProps = Parameters<typeof AntButton>[0] & {
  variant?:
    | 'primary'
    | 'secondary'
    | 'cancel'
    | 'close'
    | 'confirm'
    | 'link'
    | 'light'
  /**
   * @description If provided, will add icon to the button at the end, to show popover detail description.
   */
  actionDescription?: Parameters<typeof Popover>[0]
}

type FileUploadDefaultProps = ButtonProps & {
  mode?: FileGetterMode
  current?: File
  onFileSelected?: <T extends FileGetterMode>(
    file: T extends 'single' ? File : File[],
  ) => void
  onClick?: (
    e: Parameters<React.MouseEventHandler<HTMLElement>>[0],
    inputRef: React.RefObject<HTMLInputElement>['current'],
  ) => void
  accept?: string
  fileValidation?: (file: File) => boolean
  /**
   * @description If provided, will limit the file size to this value. (in MB)
   */
  fileSizeLimit?: number
}

export default function Button({
  memoize,
  onClick,
  variant,
  actionDescription,
  ...props
}: ButtonProps & { memoize?: (keyof ButtonProps)[] | true }) {
  const [isRunOnClickFunction, setIsRunOnClickFunction] = useSafeState(false)
  function renderButton() {
    return (
      <AntButton
        htmlType="button"
        {...omit(props, ['is__editable__cell'])}
        disabled={props?.disabled || isRunOnClickFunction}
        // className={`custom-default-btn ${props?.className || ''}`}
        className={clsx(
          'custom-default-btn',
          props?.className,
          variant && `btn-variant-${variant}`,
        )}
        onClick={async () => {
          setIsRunOnClickFunction(true)
          await onClick?.(props as any)
          setIsRunOnClickFunction(false)
        }}
      >
        {props?.children}
      </AntButton>
    )
  }
  function render() {
    if (actionDescription) {
      return (
        <div className="btn-action-description flex">
          {renderButton()}
          <Popover
            trigger="click"
            {...actionDescription}
            overlayClassName={clsx(
              'btn-action-description-popover',
              actionDescription?.overlayClassName,
            )}
            autoAdjustOverflow
          >
            <div className="show-desc-trigger flex items-center justify-center">
              <Iconify
                icon="mdi:information-slab-circle-outline"
                className={clsx(`btn-${props.type || 'default'}`)}
              />
            </div>
          </Popover>
        </div>
      )
    }
    return renderButton()
  }
  const memoDependencies = [] as any[]
  if (Array.isArray(memoize) && memoize?.length) {
    memoDependencies.push(...memoize.map((key) => get(props, key)))
  }
  const memoizedComponent = useCreation(() => {
    return render()
  }, memoDependencies)

  if (!memoize) return render()

  return memoizedComponent
}
Button.Primary = function PrimaryButton(props?: ButtonProps) {
  return <Button {...props} type="primary" />
}
Button.Cancel = function CancelButton(props?: ButtonProps) {
  const { t } = useTranslation()
  return (
    <Button data-testid="cancel-button" {...props}>
      {props?.children || t('cancel')}
    </Button>
  )
}
Button.Close = function CloseButton(props?: ButtonProps) {
  const { t } = useTranslation()
  return (
    <Button data-testid="close-button" {...props}>
      {props?.children || t('close')}
    </Button>
  )
}
Button.NativeFileUpload = function FileUpload({
  current: _current,
  children,
  mode = 'single',
  accept,
  onFileSelected,
  fileValidation,
  fileSizeLimit,
  ...props
}: FileUploadDefaultProps) {
  const { t } = useTranslation()
  const openCount = useRef(0)
  const ref = useRef<HTMLInputElement>(null)

  function allowFileSize(files: File[]) {
    const errorSizeLimit = files.find(
      (f) => !!isExceedFileLimit(f, fileSizeLimit),
    )
    if (errorSizeLimit) {
      notification.error({
        message: isExceedFileLimit(errorSizeLimit, fileSizeLimit),
      })
      return false
    }
    return true
  }

  return (
    <>
      <input
        key={`open-count-${openCount.current}`}
        readOnly
        className="hidden w-full"
        data-testid="native-file-upload"
        onChange={(e) => {
          openCount.current += 1
          if (e.target.files?.length) {
            if (fileSizeLimit && !allowFileSize(Array.from(e.target.files)))
              return

            if (mode === 'single') {
              if (fileValidation && !fileValidation(e.target.files[0])) return
              onFileSelected?.(e.target.files[0])
            } else {
              if (
                fileValidation &&
                Array.from(e.target.files).some((f) => !fileValidation(f))
              )
                return
              onFileSelected?.(e.target.files as unknown as File[])
            }
          }
        }}
        type="file"
        ref={ref}
        accept={accept}
      />
      <Button
        icon={<UploadOutlined />}
        htmlType="button"
        {...props}
        onClick={(e) => {
          /* c8 ignore next 3 */
          if (props.onClick) props.onClick(e, ref.current)
          else ref.current?.click()
        }}
      >
        {children || t('upload')}
      </Button>
    </>
  )
}

function RunFunctionButton({
  runAsync,
  ...props
}: ButtonProps & { runAsync: () => Promise<any> }) {
  const [loading, setLoading] = useState(false)
  return (
    <Button
      htmlType="button"
      {...props}
      className={`${props?.className || ''}`}
      loading={loading || props.loading}
      onClick={() => {
        setLoading(true)
        setTimeout(async () => {
          try {
            await runAsync?.()
          } catch (err: any) {
            console.error(err)
          }
          setLoading(false)
        }, 1500)
      }}
    />
  )
}

export type RequestDownloadExcelButtonProps<T = any> = {
  parameters:
    | Omit<ApiService.ExcelDownloadRequestParams<T>, 'filename'>
    | (() => Omit<ApiService.ExcelDownloadRequestParams<T>, 'filename'>)
  filename?: string
  model: ApiService.Available_BE_Models
}

function downloadExcelByModel({
  model,
  filename,
  parameters,
}: RequestDownloadExcelButtonProps & { filename: string }) {
  const _parameters =
    typeof parameters === 'function' ? parameters() : parameters
  return async () =>
    await ApiCall._generateCrud(model).download_excel({
      ..._parameters,
      filename,
    })
}

function RequestDownloadExcelButton<T = any>({
  parameters,
  model,
  filename,
  ...props
}: ButtonProps & RequestDownloadExcelButtonProps<T>) {
  const fileName = exportJSONToExcel.utils.sanitizeFileName(filename)
  return (
    <RunFunctionButton
      icon={<DownloadOutlined />}
      {...props}
      runAsync={downloadExcelByModel({ model, filename: fileName, parameters })}
    />
  )
}
RequestDownloadExcelButton.downloadExcelByModel = downloadExcelByModel
RequestDownloadExcelButton.checkExtensionRegex =
  exportJSONToExcel.utils.checkExtensionRegex
RunFunctionButton.RequestDownloadExcel = RequestDownloadExcelButton

Button.Run = RunFunctionButton
Button.Group = function ButtonGroup({
  bordered,
  ...props
}: Parameters<typeof AntButton.Group>[0] & { bordered?: boolean | 'solid' }) {
  return (
    <AntButton.Group
      {...props}
      className={clsx(
        props.className,
        bordered ? 'bordered' : null,
        bordered === 'solid' ? 'solid-border' : null,
      )}
    />
  )
}
Button.ExportJsonToExcel = function DownloadExcelWithTableData({
  exportConfig,
  ...props
}: ButtonProps & {
  exportConfig: Omit<Parameters<typeof exportJSONToExcel>[0], 'fileName'> & {
    fileName?: string
  }
}) {
  const fileName = exportJSONToExcel.utils.sanitizeFileName(
    exportConfig.fileName,
  )

  /* istanbul ignore next */
  async function download() {
    exportJSONToExcel({ ...exportConfig, fileName })
  }
  return (
    <RunFunctionButton
      icon={<DownloadOutlined />}
      {...props}
      runAsync={download}
    />
  )
}

Button.Delete = function DeleteButton({
  confirmModal,
  ...props
}: ButtonProps & {
  confirmModal?:
    | boolean
    | {
        title?: string
        body?: string
      }
}) {
  const { t } = useTranslation()
  return (
    <Button
      data-testid="delete-button"
      danger
      {...props}
      onClick={(...args) => {
        if (confirmModal) {
          // open confirm modal
          const _modalProps =
            typeof confirmModal === 'object' ? confirmModal : {}
          Modal.confirm({
            title: _modalProps.title || t('dialogDeleteTitle'),
            content: _modalProps.body || t('dialogDeleteBody'),
            okText: t('confirm'),
            cancelText: t('cancel'),
            onOk() {
              props?.onClick?.(...args)
            },
          })
        } else {
          props?.onClick?.(...args)
        }
      }}
    >
      {props?.children || t('delete')}
    </Button>
  )
}
Button.Confirm = function ConfirmButton({
  confirmModal,
  ...props
}: ButtonProps & {
  confirmModal?:
    | boolean
    | {
        title?: string
        body?: string
      }
}) {
  const { t } = useTranslation()
  return (
    <Button
      data-testid="confirm-button"
      type="primary"
      {...props}
      onClick={(...args) => {
        if (confirmModal) {
          // open confirm modal
          const _modalProps =
            typeof confirmModal === 'object' ? confirmModal : {}
          Modal.confirm({
            title: _modalProps.title || t('confirmation'),
            content: _modalProps.body || t('confirm_change'),
            okText: t('confirm'),
            cancelText: t('cancel'),
            onOk() {
              props?.onClick?.(...args)
            },
          })
        } else {
          props?.onClick?.(...args)
        }
      }}
    >
      {props?.children || t('confirm')}
    </Button>
  )
}
