import { get, isEmpty, isNil, isNull } from 'lodash'
import type { AvailableDisplayDataFormaterKeys } from 'v2source/tools/formatter'
import {
  availableDisplayDataFormater,
  DateConfig,
} from 'v2source/tools/formatter'
import type { CellObject, CellStyle } from 'xlsx-js-style'
// eslint-disable-next-line sort-imports
import { utils as xlUtils, writeFile } from 'xlsx-js-style'

import { exportExcelUtility, formatDownloadExcelFileName } from './utils'

type ColumnModel = {
  key?: string
  dataIndex: string
  title: string
  format?: AvailableDisplayDataFormaterKeys
  children?: ColumnModel[]
}
export type ExportJsonToExcelColumnModel = ColumnModel

interface InternalColumnType extends ColumnModel {
  activeColumnIndex: number
  depth: number
  children: InternalColumnType[]
  colSpan: number
}

type ExportToJSONParams = {
  columns: ColumnModel[]
  data: any[]
  formatCurrencyPrecision?: number
  multipleSheet?: boolean
  sheetNames?: string[]
  fileName: string
  cellStyles?: CellStyle[][]
}

function fitWidthToColumn(arrayOfArray: any[]) {
  // get maximum character of each column
  const isValidValue = (v: any) => !isEmpty(v)
  return arrayOfArray[0].map((_: any, i: number) => ({
    wch: Math.max(
      ...arrayOfArray.map(
        (a2) =>
          (isValidValue(a2[i])
            ? a2[i].toString().length
            : /* istanbul ignore next */ 0) + /* istanbul ignore next */ 10,
      ),
    ),
  }))
}

function assignColumnIndex(
  columns: (ColumnModel & { activeColumnIndex?: number; colSpan?: number })[],
) {
  const nesting = (
    col: ColumnModel,
    rootIndex: number,
  ): ColumnModel & { activeColumnIndex: number; colSpan: number } => {
    const subCols = [] as (ColumnModel & {
      activeColumnIndex: number
      colSpan: number
    })[]
    col.children?.forEach((item, i) => {
      const prev = subCols[i - 1]?.activeColumnIndex ?? rootIndex
      const n = prev + (subCols[i - 1]?.colSpan ?? 0)
      subCols.push(nesting(item, n))
    })
    return {
      ...col,
      activeColumnIndex: rootIndex,
      colSpan: exportExcelUtility.getColSpan(col.children || []) || 1,
      children: subCols.length ? subCols : undefined,
    }
  }
  const newCols = [] as typeof columns
  columns.forEach((item, i) => {
    const prev = newCols[i - 1]?.activeColumnIndex ?? 0
    const n = prev + (newCols[i - 1]?.colSpan ?? 0)
    newCols.push(nesting(item, n))
  })
  return newCols
}

function flattenColumns(
  columns: InternalColumnType[],
  depth: number,
  maxDepth: number,
): InternalColumnType[] {
  return columns
    .map((k) => {
      if (k.children?.length) {
        return [
          { ...k, depth },
          ...flattenColumns(k.children, depth + 1, maxDepth),
        ]
      }
      /** if not initial depth or top level header (0) and its depth not maximum depth available on entire columns then set to max (use for span row top level header)
       *  COLUMN A | COLUMN B |
       *  <span of COLUMN A> | COLUMN BB1 | COLUMN BB2 |
       * COLUMN AAA1 | COLUMN AAA2 | COLUMN BBB1 | COLUMN BBB2 |
       */
      const _d = depth > 0 && maxDepth - depth > 0 ? maxDepth : depth
      return [{ ...k, depth: _d }]
    })
    .flat(1000) as any
}

function getSheetColumns(columns: ColumnModel[]) {
  const depth = exportExcelUtility.getArrayDepth(columns)
  const flatCols = flattenColumns(
    assignColumnIndex(columns) as any,
    0,
    depth - 1,
  )
  const merges = [] as any[]
  const cols = [] as ({ v: string; t: string } | undefined | null)[][]
  const numOfActiveColumn = flatCols.filter((k) => !k.children).length
  for (let i = 0; i < depth; i++) {
    const activeColumns = Array.from(Array(numOfActiveColumn)).fill({
      v: '',
      t: 's',
    })
    // set header title according to its depth
    flatCols
      .filter((k) => k.depth === i)
      .forEach((item) => {
        const colIndex = item.activeColumnIndex
        const colMaxDepth = exportExcelUtility.getArrayDepth([item])
        if (item.colSpan > 1) {
          if (i === 0 && depth - colMaxDepth > 0) {
            const depthDiff = depth - colMaxDepth
            merges.push({
              s: { r: i, c: colIndex },
              e: { r: i + depthDiff, c: colIndex + item.colSpan - 1 },
            })
          } else {
            merges.push({
              s: { r: i, c: colIndex },
              e: { r: i, c: colIndex + item.colSpan - 1 },
            })
          }
        } else if (i === 0 && depth - colMaxDepth > 0) {
          const depthDiff = depth - colMaxDepth
          merges.push({
            s: { r: i, c: colIndex },
            e: { r: i + depthDiff, c: colIndex + item.colSpan - 1 },
          })
        }
        activeColumns[item.activeColumnIndex] = { v: item.title ?? '', t: 's' }
      })

    cols.push(activeColumns)
  }
  return {
    sheetColumns: cols,
    flatColumns: flatCols,
    depth,
    merges,
    numOfActiveColumn,
  }
}

function renderCellValue(
  value: any,
  format?: AvailableDisplayDataFormaterKeys,
) {
  let val = availableDisplayDataFormater[format || 'string'](value)
  let style = undefined as CellStyle | undefined
  if (isNull(val)) val = ''
  if (val && (format === 'ratio_to_percentage' || format === 'percentage')) {
    val += '%'
  }
  if (
    [
      'currency',
      'number',
      'ratio_to_percentage',
      'percentage',
      'number-as-text',
    ].includes(format as any)
  ) {
    style = { alignment: { horizontal: 'right' } }
  }
  return { v: val, s: style }
}

export function exportJSONToExcel({
  data,
  multipleSheet,
  columns,
  sheetNames = [],
  fileName,
  cellStyles = [],
}: ExportToJSONParams) {
  const dataSource = multipleSheet ? data : [data]
  const workbook = xlUtils.book_new()
  const { flatColumns, sheetColumns, merges } = getSheetColumns(columns)
  dataSource.forEach((rowData, i) => {
    const sheetsData: CellObject[][] = [
      ...sheetColumns,
      ...rowData.map((row: any) => {
        return flatColumns
          .filter((k) => !k.children)
          .map((col) => renderCellValue(get(row, col.dataIndex), col.format))
      }),
    ]

    if (cellStyles.length) {
      cellStyles.forEach((_row, rowIndex) => {
        _row.forEach((style, colIndex) => {
          /* istanbul ignore else */
          if (sheetsData[rowIndex][colIndex]) {
            sheetsData[rowIndex][colIndex].s = style
          }
        })
      })
    }

    const sheet = xlUtils.aoa_to_sheet<any>(sheetsData, {
      dense: true,
      cellStyles: true,
    } as any)

    /* istanbul ignore else */
    if (merges.length) sheet['!merges'] = merges

    sheet['!rows'] = sheetsData.map((_) => ({ hpx: 20 }))
    sheet['!cols'] = fitWidthToColumn(sheetsData)

    // * TO format data type of the cell
    // const formattedWorkSheet = getFormatedSheetDataType(
    //     tableProps?.columns,
    //     data[i],
    //     sheet,
    // )

    xlUtils.book_append_sheet(workbook, sheet, sheetNames[i])
  })
  const file_name = formatDownloadExcelFileName(fileName)
  writeFile(workbook, file_name)
  return null
}
exportJSONToExcel.flattenColumns = flattenColumns
exportJSONToExcel.getSheetColumns = getSheetColumns

const checkExtensionRegex = /^.*\.[^\\]+$/
exportJSONToExcel.utils = {
  checkExtensionRegex,
  sanitizeFileName(filename?: string) {
    const pageTitle = window.document
      .querySelector('main div.page-content-info')
      ?.getAttribute('title')
    let fileName =
      filename ||
      [pageTitle || '', DateConfig.date().format('YYYYMMDD_hhmm')].join('_')
    if (!fileName.match(checkExtensionRegex)) {
      fileName += '.xlsx'
    }
    return fileName
  },
  excelDateToJSDate(date: number) {
    if (isNil(date)) return undefined
    return DateConfig.date(Math.round((date - 25569) * 864e5))
  },
}
